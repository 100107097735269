import { useQuery } from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchAvailableUpgradeLicenses = ({
    licenseCode,
    options
} = {}) => {
    const query = useQuery({
        queryKey: ['getAvailableUpgradeLicenses', licenseCode],
        queryFn: () => 
            NMService.getAvailableUpgradeLicenses((licenseCode || "").split('-')[0])
                .then(response => {
                    let data = Object.entries(response.data ?? {})
                    return data.length !== 0 ? Object.fromEntries(data.filter(e => e[1] !== 0)) : {}
                })
                .catch(e => {}),
        // enabled: !!licenseCode,
        select: e => {
            let options = [];
            Object.keys(e).forEach(e => {
                e["v1lpmn"] &&
                    options.push({
                        key: "v1lpmn",
                        value: "v1lpmn",
                        text: "5x9 Lightweight Node - Micro (" + e["v1lpmn"] + ")",
                        title: "v1lpmn",
                    })
                e["v1lps"] &&
                    options.push({
                        key: "v1lps",
                        value: "v1lps",
                        text: "5x9 Lightweight Node - Medium (" + e["v1lps"] + ")",
                        title: "v1lps",
                    });
                e["v1lpm"] &&
                    options.push({
                        key: "v1lpm",
                        value: "v1lpm",
                        text: "5x9 Lightweight Node - Medium (" + e["v1lpm"] + ")",
                        title: "v1lpm",
                    });
                e["v1lpl"] &&
                    options.push({
                        key: "v1lpl",
                        value: "v1lpl",
                        text: "5x9 Lightweight Node - Large (" + e["v1lpl"] + ")",
                        title: "v1lpl",
                    })
            })
            return options
        },
        ...options
    })
    return { ...query, data: query.data ?? [] }
}