import { useEffect } from "react";
import { useAtom, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { useFetchTenants } from "../../hooks/useFetchTenants";
import { InstaceActions, multiTenantAtom } from "../../store/multiTenant";
import NMService from "../../services/nm.service";
import MultiTenantForm from "./MultiTenantForm";
import MultiTenantTable from "./MultiTenantTable";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import { addGlobalMessageAtom } from "../../store/globalMessage";
import { useAuth } from "../../hooks/useAuth";


const MultiTenant = () => {
    const navigate = useNavigate();
    const { hidrateUserData } = useAuth();
    const [state, dispatch] = useAtom(multiTenantAtom);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);

    const {
        data,
        isLoading: tenantListIsLoading,
        isFetching: tenantListIsFetching,
        refetch: refetchTenantList
    } = useFetchTenants({});

    const onSetTenant = async () => {
        const uuid = state.selectedTenantUuid;
        if (!uuid) {
            return;
        }

        await NMService.setTenant({
            tenantid: uuid
        }).then((response) => {
            hidrateUserData(response.data);
            navigate("/")
        }).catch(e => null)
    }

    const onDeleteTenant = async () => {
        const uuid = state.selectedTenantUuid;
        if (!uuid) {
            return;
        }

        await NMService.deleteTenant(state.selectedTenantUuid).then(() => {
            addGlobalMessage({
                header: "Multitenant is deleted!",
                content: "Multitenant was successfully deleted.",
                type: 'positive',
            })
            refetchTenantList();
        }).catch(e => null)
    }

    const onActivateTenant = async () => {
        const uuid = state.selectedTenantUuid;
        if (!uuid) {
            return;
        }
        await NMService.activateTenant(state.selectedTenantUuid).then(() => {
            addGlobalMessage({
                header: "Multitenant is activated!",
                content: "Multitenant was successfully activated.",
                type: 'positive',
            })
            refetchTenantList();
        }).catch(e => null)
    }

    const onDeactivateTenant = async () => {
        const uuid = state.selectedTenantUuid;
        if (!uuid) {
            return;
        }
        await NMService.deactivateTenant(state.selectedTenantUuid).then(() => {
            addGlobalMessage({
                header: "Multitenant is deactivated!",
                content: "Multitenant was successfully deactivated.",
                type: 'positive',
            })
            refetchTenantList();
        }).catch(e => null)
    }

    useEffect(() => {
        if (tenantListIsFetching) {
            return;
        }
        dispatch({ type: InstaceActions.DeselectTenant })
        
    }, [refetchTenantList, tenantListIsFetching, dispatch,]);

    if (tenantListIsLoading) {
        return null;
    }

    return (
        <>
            <MultiTenantTable
                data={data}
                button={
                    <Button
                        type="button"
                        primary
                        size="small"
                        onClick={() => dispatch({ type: InstaceActions.AddNewTenant })}
                        content="Add new tenant"
                    />
                }
            />
            {[InstaceActions.AddNewTenant, InstaceActions.EditTenant].includes(state.showType) &&
                <MultiTenantForm />
            }

            {state.showType === InstaceActions.DeleteTenant &&
                <ConfirmationModal
                    open={true}
                    header="Delete tenant"
                    content="Are you sure you want to delete tenant?"
                    onConfirm={() => onDeleteTenant()}
                    onDismiss={() => dispatch({ type: InstaceActions.DeselectTenant })}
                />
            }

            {state.showType === InstaceActions.ActivateTenant &&
                <ConfirmationModal
                    open={true}
                    header="Tenant activation"
                    content="Are you sure you want to activate tenant?"
                    onConfirm={() => onActivateTenant()}
                    onDismiss={() => dispatch({ type: InstaceActions.DeselectTenant })}
                />
            }

            {state.showType === InstaceActions.DeActivateTenant &&
                <ConfirmationModal
                    open={true}
                    header="Delete deactivation"
                    content="Are you sure you want to deactivate tenant?"
                    onConfirm={() => onDeactivateTenant()}
                    onDismiss={() => dispatch({ type: InstaceActions.DeselectTenant })}
                />
            }

            {state.showType === InstaceActions.SetTenant &&
                <ConfirmationModal
                    open={true}
                    header="Set tenant"
                    content="Are you sure you want to set tenant?"
                    onConfirm={() => onSetTenant()}
                    onDismiss={() => dispatch({ type: InstaceActions.DeselectTenant })}
                />
            }
        </>
    )
}

export default MultiTenant;