import React, { useEffect, useMemo, useState } from 'react';
import UnassignedModule from './UnassignedModule';
import { useFetchProbeModuleBundle } from '../../../hooks/useFetchProbeModuleBundle';
import { useAtom, useSetAtom } from 'jotai';
import { unassignedModulesAtom } from '../../../store/unassignedModules';
import { getModulePermission, getProbePermission } from '../../../helpers/userPermission';
import { useUserInfo } from '../../../hooks/useAuth';
import { useFetchProbeDetails } from '../../../hooks/useFetchProbeDetails';
import { useFetchSwModules } from '../../../hooks/useFetchSwModules';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import { Divider, Header, Icon } from 'semantic-ui-react';
import NMService from '../../../services/nm.service';
import { addGlobalMessageAtom } from '../../../store/globalMessage';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import { useGetGlobalPermission } from '../../../hooks/useGetGlobalPermission';
import { NODE_ADMIN } from '../../../constants/layout';


const UnassignedModules = (props) => {
    const hasPermission = useGetGlobalPermission(NODE_ADMIN);
    const { userdata: { role: userRole, groups: userGroups} } = useUserInfo();
    const [unassignedModal, setUnassignedModal] = useState(false);
    const probeid = useMemo(() => props.probeId, [props.probeId]);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [, setUnassignedModulesState] = useAtom(unassignedModulesAtom);

    const {
        data: moduleBundle,
        refetch: refetchModuleBundle,
        isLoading: moduleBundleIsLoading,
        isFetching: moduleBundleIsFetching
    } = useFetchProbeModuleBundle({ probeid });

    const {
        data: probeDetails,
        isLoading: probeDetailsIsLoading,
    } = useFetchProbeDetails({ probeid });

    const {
        data: swModelList,
        isLoading: swModelListIsLoading
    } = useFetchSwModules({});

    useEffect(() => {
        if (moduleBundleIsFetching || moduleBundleIsLoading || probeDetailsIsLoading || swModelListIsLoading) {
            return;
        };

        const { assignmodules } = getProbePermission(userRole, userGroups, probeDetails.groups);
        const modulesPermission = (moduleBundle.unassignedmodules || []).map(e => ({
            uuid: e.uuid, 
            hasPermission: getModulePermission(
                {
                    userRole: userRole,
                    userGroups: userGroups,
                    requiredGroups: e.group
                }) || assignmodules
        }))

        const modelData = (moduleBundle.unassignedmodules ?? []).map(module => {
            const model = swModelList.find((e) => e.id === module.moduleid);
            let isFramework = Boolean(model?.data?.framework);
            let isTopLevelFramework = Boolean(model?.data?.toplevel);

            return { uuid: module.uuid, isFramework, isTopLevelFramework}
        })

        setUnassignedModulesState({
            type: 'update-permissions',
            value: {
                hasPermission: assignmodules,
                modulesPermission: modulesPermission,
                modelData: modelData
            }
        })

    }, [
        setUnassignedModulesState,
        moduleBundleIsLoading,
        moduleBundleIsFetching,
        moduleBundle,
        probeDetails,
        probeDetailsIsLoading,
        swModelList,
        swModelListIsLoading,
        userRole,
        userGroups
    ]);


    if (moduleBundleIsLoading) {
        return null
    }

    return (
        <React.Fragment>
            <Header as="h4" style={{ marginTop: '1rem', marginBottom: '0' }}>
                <div>
                    Unassigned instances &nbsp;&nbsp;
                    <PermissionsGateV hasPermission={hasPermission}>
                        <Icon
                            name="trash"
                            color="blue"
                            link 
                            title="Delete all unassigned instances"
                            onClick={() => setUnassignedModal(true)}
                        />
                    </PermissionsGateV>
                </div>
            </Header>
            <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
            {(moduleBundle?.unassignedmodules || []).map((elem) => (
                <UnassignedModule
                    key={elem.uuid}
                    probeId={probeid} 
                    moduleData={elem}
                />
            ))}
            <ConfirmationModal
                open={unassignedModal}
                header="Delete all unassigned instances"
                content="Are you sure you want to delete all unassigned instances?"
                onConfirm={async () => {
                                const unassignedmodulesLength = moduleBundle?.unassignedmodules?.length ?? 0;
                                await NMService.deleteAllUnassignedModule(probeid).catch(e => null);
                                if (unassignedmodulesLength === 0){
                                    addGlobalMessage({
                                        header: 'Delete all unassigned modules',
                                        content: `No unassigned modules to delete.`,
                                        type: 'positive',
                                    })
                                } else {
                                    await refetchModuleBundle().then(e => 
                                    {
                                        if((e.data?.unassignedmodules?.length ?? 0) !== 0){ 
                                            addGlobalMessage({
                                                header: 'Delete all unassigned modules',
                                                content: `Some unassigned modules weren't successfully deleted.`,
                                                type: 'warning',
                                            })
                                        } else {
                                            addGlobalMessage({
                                                header: 'Delete all unassigned modules',
                                                content: `All unassigned modules were successfully deleted.`,
                                                type: 'positive',
                                            })
                                        }
                                    })
                                };
                                setUnassignedModal(false)
                                }}
                onDismiss={() => setUnassignedModal(false)}
            />
        </React.Fragment>
    );
};

export default UnassignedModules;
