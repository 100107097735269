import { useOutlet } from "react-router-dom";
import { ProtectedAuthBackendLayout } from "./ProtectedAuthBackendLayout";

export const ProtectedDhcpLayout = () => {
    const outlet = useOutlet();
    return (
        <ProtectedAuthBackendLayout>
            {outlet}
        </ProtectedAuthBackendLayout>
    )
};