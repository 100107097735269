import React, { useMemo, useState } from "react";
import { Button } from "semantic-ui-react";
import ObjectDataForm from "./ObjectDataForm";
import ObjectDataFilter from "./ObjectDataFilter";
import CustomTable from "../../../components/CustomTable";

const ObjectDataTable = (props) => {
    const data = useMemo(() => props.data, [props.data]);
    const columns = useMemo(() => props.columns, [props.columns]);
    const [formData, setFormData] = useState(undefined);

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: "actions",
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            circular
                            icon="settings"
                            disabled={row.original.uuid === undefined}
                            onClick={() => setFormData({ type: "edit", data: row.original })}
                        />
                        <Button
                            circular
                            icon="trash"
                            disabled={row.original.uuid === undefined}
                            onClick={() => props.delete(row.original.uuid)}
                        />
                    </div>,
                disableGlobalFilter: true,
                disableFilters: true,
            },
        ]);
    };

    return (
        <>
            <CustomTable
                data={data}
                columns={columns}
                advancedSearch={() => <ObjectDataFilter filter={props.fetch} columns={props.columns} />}
                tableHooks={tableHooks}
                footerButton={() =>
                    <Button
                        primary
                        type="button"
                        size="tiny"
                        floated="right"
                        content="Add new entry"
                        onClick={() => setFormData({ type: "add", data: {} })}
                    />
                }
            />
            {formData?.type && (
                <ObjectDataForm
                    data={formData}
                    columns={columns}
                    add={(e) => {
                        props.addEntry(e);
                        setFormData(undefined);
                    }}
                    close={() => setFormData(undefined)}
                />
            )}
        </>
    );
};

export default ObjectDataTable;
