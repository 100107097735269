import React, { useEffect, useMemo, useState} from 'react';
import { Divider, Header, Icon } from 'semantic-ui-react';
import { useAtom, useSetAtom } from 'jotai';
import { uuidv4 } from '../../../utils/uuidv4';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import InstallModuleForm from './InstallModuleForm';
import InstalledModule from './InstalledModule';
import LicenseNum from '../../../layouts/LicenseNum/LicenseNum';
import { useFetchProbeDetails } from '../../../hooks/useFetchProbeDetails';
import { useFetchProbeLicense } from '../../../hooks/useFetchProbeLicense';
import { useFetchProbeModuleBundle } from '../../../hooks/useFetchProbeModuleBundle';
import { installedModulesAtom } from '../../../store/installedModules';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import NMService from '../../../services/nm.service';
import { addGlobalMessageAtom } from '../../../store/globalMessage';

const freeLicenses = 1;
const modulesPerLicense = {
    v1lpmn: 2 + freeLicenses,
    v1lps: 5 + freeLicenses,
    v1lpm: 10 + freeLicenses,
    v1lpl: -1,
};

const InstalledModules = (props) => {
    const probeId = useMemo(() => props.probeId, [props.probeId]);
    const [installedModal, setInstalledModal] = useState(false);
    const [state, dispatch] = useAtom(installedModulesAtom);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);

    const {
        data: probeDetails,
        isLoading: probeDetailsIsLoading
    } = useFetchProbeDetails({
        probeid: probeId
    });

    const {
        data: probeLicense
    } = useFetchProbeLicense({
        probeid: probeId
    })

    const {
        data: moduleBundle,
        refetch: refetchModuleBundle,
        isLoading: moduleBundleIsLoading
    } = useFetchProbeModuleBundle({ probeid: probeId });

    const setShowType = (type = '') => {
        dispatch({
            type: 'set-showType',
            value: type,
        })
    }

    useEffect(() => {
        if (!probeLicense?.quantity || moduleBundleIsLoading) {
            dispatch({
                type: 'set-license-info',
                value: {
                    totalLicenseNum: 0,
                    remainLicenseNum: 0,
                    usedLicenseNum: 0
                }
            })
        } else {
            const totalLicenseNum = modulesPerLicense[probeLicense?.licenseCode?.split("-")[0]];
            dispatch({
                type: 'set-license-info',
                value: {
                    totalLicenseNum: totalLicenseNum,
                    remainLicenseNum: totalLicenseNum - (moduleBundle?.installedmodules?.length ?? totalLicenseNum),
                    usedLicenseNum: moduleBundle?.installedmodules?.length ?? totalLicenseNum
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        moduleBundleIsLoading,
        probeLicense,
        probeLicense.quantity,
        moduleBundle,
        // state.license.quantity
    ]);

    if (probeDetailsIsLoading || moduleBundleIsLoading) {
        return null
    }

    return (
        <>
            <Header as="h4" style={{ marginTop: '1rem', marginBottom: '0' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    Installed modules
                    &nbsp;&nbsp;
                    <LicenseNum>
                        {`(${state.usedLicenseNum}/${state.totalLicenseNum > -1 ? state.totalLicenseNum : "∞"})`}
                    </LicenseNum>
                    &nbsp;&nbsp;&nbsp;
                    <PermissionsGateV hasPermission={state.hasPermission && (state.totalLicenseNum === -1 ? true : state.remainLicenseNum > 0)}>
                        <Icon
                            name="plus"
                            color="blue"
                            title="Add module"
                            link
                            onClick={() => setShowType('install-new-module')}
                        />
                    </PermissionsGateV>
                    &nbsp;
                    <PermissionsGateV hasPermission={state.hasPermission}>
                        <Icon
                            name="trash"
                            color="blue"
                            link 
                            title="Delete all installed modules"
                            onClick={() => setInstalledModal(true)}
                        />
                    </PermissionsGateV>
                </div>
            </Header>
            <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
            {state.showType === 'install-new-module' && (
                <InstallModuleForm probeId={probeDetails.id}/>
            )}
            {(moduleBundle?.installedmodules || []).map((elem) =>
                <InstalledModule
                    key={uuidv4()}
                    probeId={probeDetails.id}
                    remainLicenseNum={state.remainLicenseNum}
                    moduleData={elem}
                    disabled={
                        (moduleBundle.unassignedmodules || []).some(
                            (e) => e.moduleid + e.version === elem.module + elem.version
                        ) ||
                        (moduleBundle.assignedmodules || []).some(
                            (e) => e.moduleid + e.version === elem.module + elem.version
                        )
                    }
                />
            )}
            <ConfirmationModal
                open={installedModal}
                header="Delete all installed modules"
                content="Are you sure you want to delete all installed modules?"
                onConfirm={async () => {
                                const installedmodulesLength = moduleBundle?.installedmodules?.length ?? 0;
                                await NMService.deleteAllInstalledModule(probeId).catch(e => null);
                                if (installedmodulesLength === 0){
                                    addGlobalMessage({
                                        header: 'Delete all installed modules',
                                        content: "No installed modules to delete.",
                                        type: 'positive',
                                    })
                                } else {
                                    await refetchModuleBundle().then(e => {
                                        if ((e.data?.installedmodules?.length ?? 0) !== 0){ 
                                            addGlobalMessage({
                                                header: 'Delete all installed modules',
                                                content: "Some installed modules weren't successfully deleted.",
                                                type: 'warning',
                                            })
                                        } else {
                                            addGlobalMessage({
                                                header: 'Delete all installed modules',
                                                content: "All installed modules were successfully deleted.",
                                                type: 'positive',
                                            })
                                        }
                                    })
                                };
                                setInstalledModal(false)
                            }}
                onDismiss={() => setInstalledModal(false)}
            />
        </>
    );
};

export default InstalledModules;
