import { atom } from 'jotai';
import { atomWithReducer } from 'jotai/utils';

const INITIAL_STATE = {
    id: '',
    showType: '',
    license: {
        uuid: '',
        name: '',
        quantity: 0,
    },
    permissions: {
        assignmodules: false,
        editbasicdata: false,
        editpermissions: false
    }
}

const editProbeReducer = (prevState, { type, value }) => {
    switch (type) {
        case "set-permissions":
            return {
                ...prevState,
                id: value.id,
                permissions: { ...value.permissions }
            };
        case "set-license":
            return {
                ...prevState,
                showType: '',
                license: {
                    uuid: value.uuid,
                    name: value.name,
                    quantity: value.quantity,
                }
            }
        case "set-type":
            return {
                ...prevState,
                showType: value
            };
        case "close-license-modal":
            return {
                ...prevState,
                showType: ''
            };
        default:
            return INITIAL_STATE
    }
}

export const editProbeAtom = atomWithReducer(INITIAL_STATE, editProbeReducer);

export const probePermissionByTypeAtom = (type) => atom(
    get => get(editProbeAtom).permissions[type]
)

export const probePermissionsAtom = atom(get => get(editProbeAtom).permissions);