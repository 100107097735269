import { useForm } from "react-hook-form";
import { useFormFields } from "../../../../hooks/useFormFields";
import { Form, FormGroup } from "semantic-ui-react";
import { useEffect, useState } from "react";
import CustomHeader from "../../../../components/CustomHeader";

export const SNMPNotificationForm = (props) => {
    const [showTemplate, setShowTemplate] = useState(false)
    const templateKeys = [
        [["alarm", "Measurements alarm trigger body"], ["clear", "Measurements alarm clear body"]],
        [["alarm-subject", "Alarm trigger subject"], ["clear-subject", "Alarm clear subject"]],
        [["alarmprobe", "Probe alarm trigger body"], ["clearprobe", "Probe alarm clear body"]],
        [["alarmprobe-subject", "Probe alarm trigger subject"], ["clearprobe-subject", "Probe alarm clear subject"]],
        [["alarmsystem", "System alarm trigger body"], ["clearsystem", "System alarm clear body"]],
        [["alarmsystem-subject", "System alarm trigger subject"], ["clearsystem-subject", "System alarm clear subject"]],
    ];

    const defaultConfig = {
        "snmp-server": "",
        "snmp-port": 161,
        "snmp-version": "v2c",
        "snmp-community": "public",
        "snmp-baseoid": "",
        ...Object.entries(props.template)
                        .map((e) => ({ [e[0].replaceAll(".", "-")]: e[1] }))
                        .reduce((e, k) => ({ ...e, ...k }), {}),
    };

    const {
        register,
        setValue,
        reset,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: defaultConfig });

    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    useEffect(() => {
        if (props.data) {
            reset({
                ...Object.entries(props.data.parameters)
                        .map((e) => ({ [e[0].replaceAll(".", "-")]: e[1] }))
                        .reduce((e, k) => ({ ...e, ...k }), {}),
                name: props.data.name,
                ...Object.entries(props.data.template)
                                .map((e) => ({ [e[0].replaceAll(".", "-")]: e[1] }))
                                .reduce((e, k) => ({ ...e, ...k }), {}),
            });
        }
    }, [reset, props.data]);

    return (
        <Form onSubmit={handleSubmit(props.post)}>
            <FormGroup widths={4}>{renderInput("Name", "name")}</FormGroup>
            <FormGroup widths={4}>
                {renderInput("Server", "snmp-server")}
                {renderInput("Port", "snmp-port", { inputType: "number" })}
                {renderInput("Community", "snmp-community")}
            </FormGroup>
            <Form.Group widths={4}>
                {renderInput("Version", "snmp-version")}
                {renderInput("Base OID", "snmp-baseoid")}
            </Form.Group>
            <CustomHeader
                title='Template'
                toggle={() => setShowTemplate(p => !p)}
                show={showTemplate}
            />
            {showTemplate && templateKeys.map(e =>
                    <Form.Group key={e[0][0]}>
                        {renderInput(e[0][1], e[0][0], { width: 11, inputType: 'textarea', rows: 7, mono: true })}
                        {renderInput(e[1][1], e[1][0], { width: 5, inputType: 'textarea', rows: 7, mono: true })}
                    </Form.Group>
                )}
            <Form.Group>
                <Form.Button primary type="submit" content={props.data?.id ? "Update" : "Add"} />
                <Form.Button type="button" content="Cancel" onClick={props.close} />
            </Form.Group>
        </Form>
    );
};
