import React, { useMemo } from 'react';
import { Button } from 'semantic-ui-react';
import { useAtom } from 'jotai';
import { ADMIN } from '../../constants/layout';
import { useGetGlobalPermission } from '../../hooks/useGetGlobalPermission';
import { globalSnmpSecurityProfileActions, globalSnmpSecurityProfileAtom } from '../../store/globalSnmpSecurityProfiles';
import { PermissionsGateV } from '../../layouts/PermissionGate/PermissionGateV';
import CustomTable from '../../components/CustomTable';

function SecurityProfileTable(props) {
    const hasPermision = useGetGlobalPermission(ADMIN);
    const [, dispatch] = useAtom(globalSnmpSecurityProfileAtom);
    const { AddSecurityProfile, EditSecurityProfile, DeleteSecurityProfile } = globalSnmpSecurityProfileActions;

    const data = useMemo(() => props.data, [props.data]);
    const columns = useMemo(
        () => [
            {
                id: 'name',
                Header: 'Name',
                accessor: 'name',
            },

            {
                id: 'community',
                Header: 'Community',
                accessor: 'community',
            },
            {
                id: 'snmp_version',
                Header: 'SNMP version',
                accessor: 'snmp_version',
                width: 2,
            },
        ],
        []
    );

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: 'actions',
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <PermissionsGateV hasPermission={hasPermision}>
                            <Button
                                circular
                                icon="edit"
                                type="button"
                                onClick={() => dispatch({ type: EditSecurityProfile, value: row.original })}
                            />
                        </PermissionsGateV>
                        <PermissionsGateV hasPermission={hasPermision}>
                            <Button
                                circular
                                icon="trash"
                                type="button"
                                onClick={() => dispatch({ type: DeleteSecurityProfile, value: row.original })}
                            />
                        </PermissionsGateV>
                    </div>,
                disableGlobalFilter: true,
                disableFilters: true,
                width: 2,
            },
        ]);
    };

    return (
        <CustomTable
            data={data}
            columns={columns}
            tableHooks={tableHooks}
            footerButton={() => 
                <Button
                    primary
                    floated='right'
                    size='tiny'
                    type="button"
                    content="Add new security profile"
                    onClick={() => dispatch({ type: AddSecurityProfile })}
                />
            }
        />
    );
}

export default SecurityProfileTable;
