import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Header } from "semantic-ui-react";
import { useFormFields } from "../../../hooks/useFormFields";
import { useGetGlobalPermission } from "../../../hooks/useGetGlobalPermission";
import { NODE_ADMIN } from "../../../constants/layout";
import { PermissionsGateV } from "../../../layouts/PermissionGate/PermissionGateV";
import NMFieldValidator from "../../../utils/NMFieldValidator";

const DestinationForm = (props) => {
    const hasPermision = useGetGlobalPermission(NODE_ADMIN);
    const {
        handleSubmit,
        register,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm();

    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    const onSubmit = (values) => {
        const { port, ...rest } = values;
        for (const val in rest) {
            rest[val] = rest[val].trim()
        }
        props.add({ port, ...rest });
    };

    useEffect(() => {
        props.data.type === "edit"
            ? reset(props.data.data)
            : reset(props.columns.map((e) => e.id).reduce((a, v) => ({ ...a, [v]: "" }), {}));
    }, [props.data, props.columns, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Header
                as="h4"
                dividing
                content={props.data.type === "add" ? "Add new destination" : "Edit destination"}
                style={{ margin: "1.5rem 0" }}
            />
            <Form.Group widths={4}>
                {renderInput("Region", "region", { validate: (v) => NMFieldValidator.validateString(v, /^[a-z][a-z0-9_-]{1,7}$/, 1, 8, false), notRequired: true })}
                {renderInput("Location", "location", { notRequired: true })}
            </Form.Group>
            <Form.Group widths={4}>
                {renderInput("Device type", "devicetype", { validate: (v) => NMFieldValidator.validateString(v, /^[a-z][a-z0-9_-]{1,31}$/, 1, 32, false), notRequired: true })}
                {renderInput("Device role", "devicerole", { validate: (v) => NMFieldValidator.validateString(v, /^[a-z][a-z0-9_-]{1,31}$/, 1, 32, false), notRequired: true })}
                {renderInput("Device group", "devicegroup", { validate: (v) => NMFieldValidator.validateString(v, /^[a-z][a-z0-9_-]{1,31}$/, 1, 32, false), notRequired: true })}
            </Form.Group>
            <Form.Group widths={4}>
                {renderInput("Name", "name", { validate: (v) => NMFieldValidator.validateString(v, /^[a-z][a-z0-9_-]{1,63}$/, 1, 64, true)})}
                {renderInput("Protocol", "protocol", { validate: (v) => NMFieldValidator.validateString(v, /^[a-z][a-z0-9_-]{1,15}$/, 1, 16, false), notRequired: true})}
                {renderInput("Destination", "address", { validate: (v) => NMFieldValidator.validateString(v, /^[a-zA-Z0-9][A-Za-z0-9.:_-]{1,127}$/, 1, 128, true),})}
                {renderInput("Port", "port", { notRequired: true, inputType: "number", min: 1, max: 65535 })}
            </Form.Group>
            <Form.Group>
                <PermissionsGateV hasPermission={hasPermision}>
                    <Form.Button primary type="submit" content={props.data.type === "edit" ? "Update" : "Add"} />
                </PermissionsGateV>
                <Form.Button type="button" content="Cancel" onClick={props.close} />
            </Form.Group>
        </Form>
    );
};

export default DestinationForm;
