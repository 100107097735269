import { Button, Grid, Icon, Popup } from "semantic-ui-react";
import { PermissionsGateV } from "../../layouts/PermissionGate/PermissionGateV";
import { useMemo } from "react";

const ProbeLicense = (props) => {
    const probeLicense = useMemo(() => props.probeLicense, [props.probeLicense])
    const availableUpgradeLicense = useMemo(() => props.availableUpgradeLicense, [props.availableUpgradeLicense])
    const hasPermission = useMemo(() => props.hasPermission, [props.hasPermission])

    const switchName = (name) => {
        switch (name) {
            case "name":
                return "Name";
            case "description":
                return "Description";
            case "licenseType":
                return "License type";
            case "company":
                return "Company";
            case "quantity":
                return "Quantity";
            case "serialNumber":
                return "Serial number";
            case "licenseKey":
                return "License key";
            case "issueDate":
                return "Issue date";
            case "licenseCode":
                return "License code";
            case "features":
                return "Features";
            case "active":
                return "Active";
            case "sla":
                return "SLA";
            case "warranty":
                return "Warranty";
            case "Updates":
                return "Updates";
            default:
                return name
        }
    };

    return (
        <label style={{ float: 'right', fontWeight: '500', margin: '1rem 0 0 0', paddingBottom: '0' }}>
            {probeLicense?.name ?<>
                License: {probeLicense?.name}&ensp;
                <Popup
                    position="bottom left"
                    trigger={probeLicense && <Icon link circular color='blue' size="small" name="info" />}
                    on='click'
                    style={{ minWidth: "400px", padding: "2rem 0 2rem 0" }}
                >
                    {probeLicense ?
                        <Popup.Content>
                            <Grid>
                                {Object.keys(probeLicense).map((elem) =>
                                    (elem !== "uuid" && elem !== "remaining" && probeLicense[elem]) ? (
                                        <Grid.Row key={elem} style={{ padding: "2px 10px 2px 10px" }}>
                                            <Grid.Column width={6} textAlign="right" style={{ fontWeight: "bold" }}>
                                                {switchName(elem)} :
                                            </Grid.Column>
                                            <Grid.Column width={10}>
                                                {elem === "active"
                                                    ? probeLicense[elem]
                                                        ? "true"
                                                        : "false"
                                                    : probeLicense[elem]}
                                            </Grid.Column>
                                        </Grid.Row>)
                                        : null
                                )}
                            </Grid>
                        </Popup.Content> :
                        <Popup.Content>
                            No applied license
                        </Popup.Content>}
                </Popup>
                {(availableUpgradeLicense && Object.keys(availableUpgradeLicense).length !== 0) && probeLicense?.name &&
                    <Popup
                        content="Upgrade license"
                        trigger={
                            <PermissionsGateV hasPermission={hasPermission}>
                                <Icon
                                    link
                                    circular
                                    color='blue'
                                    size="small"
                                    name="upload"
                                    onClick={() => props.setShowModal('upgradeLicense')}
                                />
                            </PermissionsGateV>}
                    />}
                {probeLicense &&
                    <Popup
                        content="Remove license"
                        trigger={
                            <PermissionsGateV hasPermission={hasPermission}>
                                <Icon
                                    link
                                    circular
                                    color='red'
                                    size="small"
                                    name="trash"
                                    onClick={() => props.setShowModal('removeLicense')}
                                />
                            </PermissionsGateV>}
                    />}
            </>
            :
            <PermissionsGateV hasPermission={hasPermission}>
                <Button
                    content="Add node license"
                    color="red"
                    size="tiny"
                    onClick={() => props.setShowModal('assignLicense')}
                />
            </PermissionsGateV>}
        </label>
)}

export default ProbeLicense;