import { useEffect, useMemo, useState } from 'react';
import { Divider, Header, Icon } from 'semantic-ui-react';
import { useAtom, useAtomValue, useSetAtom} from 'jotai';
import { useUserInfo } from '../../../hooks/useAuth';
import AssignedModule from './AssignedModule';
import ModuleAsTemplate from './ModuleAsTemplate';
import TemplateToModules from './TemplateToModules';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import { getModulePermission, getProbePermission } from '../../../helpers/userPermission';
import { useFetchProbeDetails } from '../../../hooks/useFetchProbeDetails';
import { useFetchProbeModuleBundle } from '../../../hooks/useFetchProbeModuleBundle';
import { useFetchSwModules } from '../../../hooks/useFetchSwModules';
import { usedInstancesAtom, usedInstancesHasPermissionAtom } from '../../../store/assignedModules';
import { useFetchProbeLicense } from '../../../hooks/useFetchProbeLicense';
import NMService from '../../../services/nm.service';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import { addGlobalMessageAtom } from '../../../store/globalMessage';

const AssignedModules = (props) => {
    const { userdata: { role: userRole, groups: userGroups} } = useUserInfo();
    const [unusedModal, setUnusedModal] = useState(false)
    const probeId = useMemo(() => props.probeId, [props.probeId]);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [usedInstancesState, setUsedInstancesState] = useAtom(usedInstancesAtom);
    const usedInstancesHasPermission = useAtomValue(usedInstancesHasPermissionAtom);

    const {
        data: probeDetails,
        isLoading: probeDetailsIsLoading,
    } = useFetchProbeDetails({ probeid: props.probeId });

    const {
        data: probeLicense
    } = useFetchProbeLicense({ probeid: probeId })

    const {
        data: moduleBundle,
        refetch: refetchModuleBundle,
        isLoading: moduleBundleIsLoading,
    } = useFetchProbeModuleBundle({ probeid: props.probeId });

    const {
        data: swModelList,
        isLoading: swModelListIsLoading,
    } = useFetchSwModules();

    useEffect(() => {
        if (
            moduleBundleIsLoading ||
            probeDetailsIsLoading ||
            swModelListIsLoading
        ) {
            return;
        };

        const { assignmodules } = getProbePermission(userRole, userGroups, probeDetails.groups);
        const modulesPermission = (moduleBundle.assignedmodules || []).map(e => ({
            uuid: e.uuid,
            hasPermission: getModulePermission(
                {
                    userRole: userRole,
                    userGroups: userGroups,
                    requiredGroups: e.group
                }) || assignmodules
        }))

        const modulesDescendants = (moduleBundle.assignedmodules || []).map(module => {
            return {
                uuid: module.uuid,
                subFrameworks: (moduleBundle.assignedmodules || []).filter(e => (
                    e.parent === module.uuid && swModelList.find(model => (
                        model.id === e.moduleid && model.data.framework && model.data.toplevel
                    ))
                )).length,
                submodules: (moduleBundle.assignedmodules || []).filter(e => (
                    e.parent === module.uuid && swModelList.find(model => (
                        model.id === e.moduleid && !model.data.framework && !model.data.toplevel
                    ))
                )).length
            }
        });
        let frameworks = (moduleBundle.assignedmodules || []).filter(e => (
                swModelList.find(model => (
                    model.id === e.moduleid && model.data.framework && model.data.toplevel
                ))
            ))

        setUsedInstancesState({
            type: 'update-permissions',
            value: {
                hasPermission: assignmodules,
                modulesPermission: modulesPermission,
                modulesDescendants: modulesDescendants,
                frameworks: frameworks
            }
        })
    }, [
        moduleBundleIsLoading,
        moduleBundle,
        swModelList,
        swModelListIsLoading,
        probeDetails,
        probeDetailsIsLoading,
        setUsedInstancesState,
        userRole,
        userGroups
    ]);

    return probeDetailsIsLoading || moduleBundleIsLoading || swModelListIsLoading ? null : (
        <>
            <Header as="h4" style={{ marginTop: '1rem', marginBottom: '0' }}>
                <div>
                    Used instances &nbsp;&nbsp;&nbsp;
                    <PermissionsGateV hasPermission={usedInstancesHasPermission && !!probeLicense?.uuid}>
                        <Icon
                            name="upload"
                            color="blue"
                            link
                            title="Save modules as template"
                            onClick={() => setUsedInstancesState({
                                type: 'update-show-type',
                                value: 'saveModuleAsTemplate'
                            })}
                        />
                    </PermissionsGateV>
                    &nbsp;
                    <PermissionsGateV hasPermission={usedInstancesHasPermission && !!probeLicense?.uuid}>
                        <Icon
                            name="download"
                            color="blue"
                            link
                            title="Assign template to this node"
                            onClick={() => setUsedInstancesState({
                                type: 'update-show-type',
                                value: 'assignTemplateToProbe'
                            })}
                        />
                    </PermissionsGateV>
                    &nbsp;
                    <PermissionsGateV hasPermission={usedInstancesHasPermission}>
                        <Icon
                            name="minus"
                            color="blue"
                            link 
                            title="Unassign all used instances"
                            onClick={() => setUnusedModal(true)}
                        />
                    </PermissionsGateV>
                </div>
            </Header>
            <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
            {usedInstancesState.showType === 'saveModuleAsTemplate' && (
                <ModuleAsTemplate
                    probeid={probeDetails.id}
                    closeForm={() => setUsedInstancesState({
                        type: 'update-show-type',
                        value: ''
                    })}
                />
            )}
            {usedInstancesState.showType === 'assignTemplateToProbe' && (
                <TemplateToModules
                    probeid={probeDetails.id}
                    closeForm={() => setUsedInstancesState({ type: 'update-show-type', value: '' })}
                />
            )}
            {(moduleBundle.assignedmodules || [])
                .filter(e => swModelList.find(model => (
                    model.id === e.moduleid && model.data.framework && model.data.toplevel
                )))
                .map((elem) => (
                    <AssignedModule
                        top={elem.moduleid}
                        key={elem.uuid}
                        probeId={probeDetails.id}
                        level={1}
                        moduleData={elem}
                    />
                    ))}
            <ConfirmationModal
                open={unusedModal}
                header="Unassign all used instances"
                content="Are you sure you want to unassign all used instances?"
                onConfirm={async () => {
                                const assignedmodulesLength = moduleBundle?.assignedmodules?.length ?? 0;
                                await NMService.unassignAllModule(probeId).catch(e => null);
                                if (assignedmodulesLength === 0){
                                    addGlobalMessage({
                                        header: 'Unassign all used modules',
                                        content: `No used modules to unassign.`,
                                        type: 'positive',
                                    })
                                } else {
                                    await refetchModuleBundle().then(e => {
                                        if ((e.data?.assignedmodules?.length ?? 0) !== 0){ 
                                            addGlobalMessage({
                                                header: 'Unassign all used modules',
                                                content: "Some used modules weren't successfully unassigned.",
                                                type: 'warning',
                                            })
                                        } else {
                                            addGlobalMessage({
                                                header: 'Unassign all used modules',
                                                content: `All used modules were successfully unassigned.`,
                                                type: 'positive',
                                            })
                                        }
                                    })
                                };
                                setUnusedModal(false)
                                }}
                onDismiss={() => setUnusedModal(false)}
            />
        </> 
    );
}; 

export default AssignedModules;

