import { useMemo } from "react";
import { useAtom } from "jotai";
import { Button } from "semantic-ui-react";
import { licenseMgmtActions, licenseMgmtAtom } from "../../store/licenseManagment";
import CustomTable from "../../components/CustomTable";


const LicenseTable = (props) => {
    const [, dispatch] = useAtom(licenseMgmtAtom);
    const data = useMemo(() => props.data, [props.data])

    const columns = useMemo(
        () => [
            {
                id: "name",
                Header: "Name",
                accessor: "name",
            },
            {
                id: "description",
                Header: "Description",
                accessor: "description",
            },
            {
                id: "issueDate",
                Header: "Issue date",
                accessor: "issueDate",
            },
        ],
        []
    );

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: "Actions",
                id: "actions",
                Cell: ({ row }) =>
                    <Button
                        circular
                        icon="info"
                        size="small"
                        onClick={() => dispatch({ type: licenseMgmtActions.SHOW_LICENSE_INFO, value: row.original})}
                    />,
                width: 1,
                disableGlobalFilter: true,
                disableFilters: true,
            },
        ]);
    };

    return (
        <CustomTable
            data={data}
            columns={columns}
            tableHooks={tableHooks}
            footerButton={() =>
                <Button
                    primary
                    size="tiny"
                    floated="right"
                    type="button"
                    content="Add new license"
                    onClick={() => dispatch({ type: licenseMgmtActions.SHOW_LICENSE_FORM })}
                />
            }
        />
    )
}

export default LicenseTable;