export const AUTH_TYPE_OPTIONS = [
    {
        key: 'localdatabase',
        text: 'localdatabase',
        value: 'localdatabase'
    },
    {
        key: 'tacacs',
        text: 'tacacs',
        value: 'tacacs'
    },
    {
        key: 'radius',
        text: 'radius',
        value: 'radius'
    },
    {
        key: 'ldap',
        text: 'ldap',
        value: 'ldap'
    }
]


export const STRATEGY_OPTIONS = [
    {
        key: 'all',
        text: 'all',
        value: 'all'
    },
    {
        key: 'randomall',
        text: 'randomall',
        value: 'randomall'
    },
    {
        key: 'any',
        text: 'any',
        value: 'any'
    }
]

export const TACACS_AUTH_TYPE_OPTIONS = [
    {
        key: 'PAP',
        text: 'PAP',
        value: 'PAP'
    },
    {
        key: 'CHAP',
        text: 'CHAP',
        value: 'CHAP'
    },
    {
        key: 'MSCHAP',
        text: 'MSCHAP',
        value: 'MSCHAP'
    },
    {
        key: 'MSCHAPV2',
        text: 'MSCHAPV2',
        value: 'MSCHAPV2'
    }
]

export const LDAP_PROTOCOL_OPTIONS = [
    {
        key: 'ldap',
        text: 'ldap',
        value: 'ldap'
    },
    {
        key: 'ldaps',
        text: 'ldaps',
        value: 'ldaps'
    }
]