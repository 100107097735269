
import { Fragment } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { useUserInfo } from "../hooks/useAuth";
import { ADMIN } from "../constants/layout";

export const ProtectedAuthBackendLayout = () => {
    const outlet = useOutlet();
    const { multitenant, userdata } = useUserInfo();

    if (
        multitenant &&
        userdata?.role === ADMIN &&
        userdata?.tenantid
    ) {
        return <Navigate to="/" />;
    }

    return (
        <Fragment>
            {outlet}
        </Fragment>
    )
};
