import React, { useEffect, useMemo, useState } from "react";
import DestinationTable from "./DestinationTable";
import nmService from "../../../services/nm.service";
import FileUpload from "./FileUpload";
import { Breadcrumb, Divider, Loader, Menu } from "semantic-ui-react";
import { addGlobalMessageAtom } from "../../../store/globalMessage";
import { useSetAtom } from "jotai";

const Destinations = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false)
    const [dbContent, setDbContent] = useState([]);
    const [fileContent, setFileContent] = useState([]);
    const columns = useMemo(
        () => [
            {
                id: "region",
                Header: "Region",
                accessor: d => d.region || "",
            },
            {
                id: "location",
                Header: "Location",
                accessor: d => d.location || "",
            },
            {
                id: "devicetype",
                Header: "Device Type",
                accessor: d => d.devicetype || "",
            },
            {
                id: "devicerole",
                Header: "Device Role",
                accessor: d => d.devicerole || "",
            },
            {
                id: "devicegroup",
                Header: "Device Group",
                accessor: d => d.devicegroup || "",
            },
            {
                id: "name",
                Header: "Name",
                accessor: "name",
            },
            {
                id: "protocol",
                Header: "Protocol",
                accessor: d => d.protocol || "",
            },
            {
                id: "address",
                Header: "Destination",
                accessor: "address",
            },
            {
                id: "port",
                Header: "Port",
                accessor: d => d.port || "",
            },
        ],
        []
    );

    const fetchDestinations = (filter = "") =>
        nmService
            .getObjectServiceDestinations(filter)
            .then((response) => setDbContent(response.data))
            .catch(e => []);

    const importToDatabase = () => {
        setLoading(true)
        nmService.postObjectServiceDestinationsList(
                fileContent.map((e) => {
                    delete e.uuid;
                    for (const key in e) {
                        if (!e[key]) {
                            delete e[key];
                        }
                    }
                    return e;
                })
            ).then((r) =>
                addGlobalMessage({
                    header: "Successfully imported destinations",
                    content: "Successfully imported destinations",
                    type: "positive",
                }))
            .then(r => {
                fetchDestinations();
                setFileContent([]);
            })
            .catch(e => null)
            .finally((r) => setLoading(false));
    };

    const addEntry = (entry) =>
        nmService.addObjectServiceDestination(entry).then((r) => 
                addGlobalMessage({
                    header: `Successfully ${entry.uuid ? "updated" : "added new"} destination`,
                    content: `Successfully ${entry.uuid ? "updated" : "added new"} destination`,
                    type: "positive",
                }))
                .catch(e => null)
                .finally(() => fetchDestinations());

    const deleteEntry = (uuid, type) => {
        if (type === "db") {
            nmService
                .deleteObjectServiceDestination(uuid)
                .then((r) => 
                    addGlobalMessage({
                        header: "Successfully deleted destination",
                        content: "Successfully deleted destination",
                        type: "positive",
                    }))
                .catch(e => null)
                .finally(() => fetchDestinations());
        } else {
            setFileContent((prev) => prev.filter((e) => uuid !== e.uuid));
        }
    };

    useEffect(() => {
        fetchDestinations();
    }, []);

    return (
        <>
            <Breadcrumb style={{ marginTop: "1rem" }}>
                <Breadcrumb.Section>Object service</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section active>Destinations</Breadcrumb.Section>
            </Breadcrumb>
            <Divider />
            <Menu pointing secondary widths={2}>
                <Menu.Item
                    link
                    active={open}
                    content="Database content"
                    onClick={() => setOpen(true)}
                />
                <Menu.Item
                    link
                    active={!open}
                    content="Destinations upload"
                    onClick={() => setOpen(false)}
                />
            </Menu>
            {open ? (
                <DestinationTable
                    data={dbContent}
                    type="db"
                    columns={columns}
                    delete={deleteEntry}
                    addEntry={addEntry}
                    fetch={fetchDestinations}
                />
            ) : (
                <div style={loading ? { pointerEvents: 'none', position: 'relative'} : {}}>
                    <Loader active={loading} size='big' inline='centered' style={{ position: 'absolute', top: '50%', left: '50%' }}/>
                    <FileUpload setFileContent={setFileContent} columns={columns} />
                    {fileContent.length !== 0 && (
                        <DestinationTable
                            data={fileContent}
                            type="file"
                            columns={columns}
                            delete={deleteEntry}
                            import={importToDatabase}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default Destinations;
