import React, { useEffect, useMemo, useState } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import { useForm, useFieldArray } from 'react-hook-form';
import NMService from '../../services/nm.service';
import { addGlobalMessageAtom } from '../../store/globalMessage';
import { useSetAtom } from 'jotai';
import { useFormFields } from '../../hooks/useFormFields';

function VisualizationPanelTemplateForm(props) {
    const [moduleid, setModuleId] = useState('');
    const [visPanelTemplateId, setVisPanelTemplateId] = useState('');
    const visPanelTemplates = useMemo(() => props.visPanelTemplates, [props.visPanelTemplates]);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);

    const {
        register,
        reset,
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm();

    const { renderInput, renderCheckbox } = useFormFields({ register, errors, setValue, watch });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'variables',
    });

    const onSubmit = (values) => {
        let { variables, templateid, templatename, template, iteratefield, enabled } = values;
        let data = {
            moduleid: moduleid,
            templateid: templateid.trim(),
            templatename: templatename.trim(),
            template: JSON.parse(template),
            variables: {},
            iteratefield: (iteratefield || "").trim(),
            enabled: !!enabled,
        };

        if (Array.isArray(variables)) {
            variables.forEach((e) => {
                const key = '${' + e.variable.trim() + '}';
                const value = e.description.trim();
                data.variables[key] = value;
            });
        }

        NMService.updateSWModuleVisPanelTemplate(data).then((response) => {
            props.closeForm();
            props.refreshDashboards();
            addGlobalMessage({
                header: `Visualization panel template was ${visPanelTemplateId ? 'updated' : 'created'
                    }!`,
                content: `Visualization panel template was successfully ${visPanelTemplateId ? 'updated' : 'created'
                    }.`,
                type: 'positive',
            })
        }).catch(e => null);
    };

    useEffect(() => {
        setModuleId(props.moduleid);

        let initconfig = {};
        if (props.selectedVisPanelTemplateId) {
            setVisPanelTemplateId(props.selectedVisPanelTemplateId);
            try {
                const { variables, template, iteratefield, ...rest } = visPanelTemplates.find(
                    (e) => e.templateid === props.selectedVisPanelTemplateId
                );

                const variablesArr = [];
                for (const item in variables) {
                    variablesArr.push({
                        variable: item.substring(item.indexOf('{') + 1, item.lastIndexOf('}')),
                        description: variables[item],
                    });
                }

                initconfig = {
                    ...rest,
                    variables: variablesArr,
                    iteratefield: (iteratefield || "").trim(),
                    template: JSON.stringify(template, null, 2),
                };
            } catch {
                initconfig.template = JSON.stringify({}, null, 2);
            }
        } else {
            setVisPanelTemplateId('');
        }

        reset(initconfig);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedVisPanelTemplateId, props.moduleid]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '2rem', marginLeft: '2rem' }}>
            <Form.Group widths={4}>
                {renderInput('Template ID', 'templateid', { disabled: Boolean(props.selectedVisPanelTemplateId) })}
                {renderInput('Template name', 'templatename')}
                {renderInput('Iterate field', 'iteratefield', { notRequired: true })}
                {renderCheckbox('Enabled', 'enabled')}
            </Form.Group>
            <Form.Group widths={4}>
                <Form.Field inline>
                    Variables&ensp;
                    <Icon
                        name="plus"
                        link
                        style={{ color: '#4183C4' }}
                        onClick={() => append({ variable: '', description: '' })}
                    />
                </Form.Field>
            </Form.Group>
            {fields.map((field, index) => (
                <Form.Group widths={4} key={field.id}>
                    <Form.Field>
                        <Icon
                            link
                            name="trash alternate"
                            style={{ color: '#4183C4', marginLeft: '1rem', float: 'right' }}
                            onClick={() => remove(index)}
                        />
                    </Form.Field>
                    {renderInput(`Variable ${index} name`, `variables.${index}.variable`, { hideLabel: true })}
                    {renderInput(`Variable ${index} description`, `variables.${index}.description`, { hideLabel: true, notRequired: true })}
                </Form.Group>
            ))}
            <Form.Group style={{ marginTop: '2rem' }}>
                {renderInput('Template', 'template', { width: 16, inputType: 'textarea', rows: 15 })}
            </Form.Group>
            <Form.Group>
                <Form.Button size="small" primary content={visPanelTemplateId ? 'Update' : 'Create'} disabled={props.disabled}/>
                {visPanelTemplates.length > 0 && <Form.Button size="small" type="button" content='Cancel' onClick={props.closeForm}/>}
            </Form.Group>
        </Form>
    );
}

export default VisualizationPanelTemplateForm;