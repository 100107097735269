export const prepareConfig = (values) => {
    if (values === undefined || values === null) {
        return;
    }

    const data = {};

    Object.keys(values).forEach((key) => {
        const value = values[key];

        if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
                data[key] = value.map((item) => {
                    if (typeof item === 'object') {
                        if (item.hasOwnProperty('var')) {
                            return item.var;
                        }
                        return prepareConfig(item);
                    } else {
                        return item;
                    }
                }).filter(e => e);
            } else {
                data[key] = prepareConfig(value);
            }
        } else {
            data[key] = value;
        }
    });

    return data;
};