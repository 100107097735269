import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { Checkbox, Menu, Button, Table, Grid, Dropdown } from 'semantic-ui-react';
import NMService from '../../services/nm.service';
import { uuidv4 } from '../../utils/uuidv4';
import GlobalFilter from '../../components/globalFilter/GlobalFilter';
import MultiSelectColumnFilter from '../../components/multiSelectColumnFilter/MultiSelectColumnFilter';
import { useGetGlobalPermission } from '../../hooks/useGetGlobalPermission';
import { ADMIN } from '../../constants/layout';
import { PermissionsGateV } from '../../layouts/PermissionGate/PermissionGateV';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { useSetAtom } from 'jotai';
import { addGlobalMessageAtom } from '../../store/globalMessage';


function AlarmsTable(props) {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const hasPermision = useGetGlobalPermission(ADMIN)
    const [advFilter, setAdvFilter] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [selectedAlarmId, setSelectedAlarmId] = useState(props.selectedAlarmId);

    useEffect(() => {
        setSelectedAlarmId(props.selectedAlarmId);
    }, [props.selectedAlarmId]);

    const {
        data: severityList = [],
    } = useQuery({
        queryKey: ['NMService', 'getAlarmSeverityList'],
        queryFn: () =>
            NMService.getAlarmSeverityList()
                .then(r => r.data)
                .catch(e => []),
    });

    const {
        data: nodeList = [],
    } = useQuery({
        queryKey: ['NMService', 'getNodes'],
        queryFn: () =>
            NMService.getNodes()
                .then(r => r.data)
                .catch(e => []),
    });

    const {
        data: allModules = [],
    } = useQuery({
        queryKey: ['getAllNodeModules'],
        queryFn: () =>
            NMService.getAllNodeModules()
                .then(r => r.data)
                .catch(e => []),
    });

    const {
        data: allLocations = [],
    } = useQuery({
        queryKey: ['getAllLocations'],
        queryFn: () =>
            NMService.getLocations()
                .then(r => r.data)
                .catch(e => []),
    });

    const resultModelList = useMemo(() => props.resultModels, [props.resultModels]);
    const data = useMemo(() => props.data, [props.data]);
    const columns = useMemo(
        () => [
            {
                id: 'scope',
                Header: 'Scope',
                accessor: 'scope',
                Filter: MultiSelectColumnFilter,
                filter: 'multiple',
                disableFilters: false,
                width: 1,
            },
            {
                id: 'module',
                Header: 'Module',
                accessor: 'module',
                Filter: MultiSelectColumnFilter,
                filter: 'multiple',
                disableFilters: false,
                width: 2,
            },
            {
                id: 'definition',
                Header: 'Definition',
                accessor: 'rule',
                Cell: ({ row }) => {
                    const moduleInfo = (resultModelList || [])?.find((e) => e.id === row.original.module);
                    let formattedrule = row.original.rule;
                    if (moduleInfo?.resultmodel) {
                        const { resultmodel } = moduleInfo;
                        resultmodel.forEach((e) => {
                            formattedrule = formattedrule.replace(e.path, e.description);
                        });
                    }
                    return (
                        <React.Fragment>
                            <p>{formattedrule}</p>
                        </React.Fragment>
                    );
                },
                width: 3,
            },
            {
                id: 'custom_message',
                Header: 'Custom message',
                accessor: 'message',
                width: 2,
            },
            {
                id: 'location',
                Header: 'Location',
                accessor: (d) => d.location.name || "",
                width: 1,
            },
            {
                id: 'tag',
                Header: 'Tag',
                accessor: d => d.tag || "",
                width: 1,
            },
            {
                id: 'count',
                Header: 'Count',
                accessor: 'count',
                width: 1,
            },
            {
                id: 'interval',
                Header: 'Interval',
                accessor: 'interval',
                width: 1,
            },
            {
                id: 'severity',
                Header: 'Severity',
                accessor: 'severity',
                Cell: ({ row, severityList }) => {
                    try {
                        const { severity, description } = (severityList || [])?.find(
                            (e) => e.severity === row.original.severity
                        );
                        return `${description} (${severity})`;
                    } catch {
                        return '';
                    }
                },
                Filter: MultiSelectColumnFilter,
                filter: 'multiple',
                disableFilters: false,
                width: 2,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const filterTypes = useMemo(
        () => ({
            multiple: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = String(row.values[id]);

                    if (rowValue === undefined) {
                        return false;
                    }

                    if (Array.isArray(rowValue)) {
                        return filterValue.some((e) => rowValue.includes(e));
                    } else {
                        return filterValue.includes(rowValue);
                    }
                });
            },
        }),
        []
    );

    const defaultColumn = useMemo(
        () => ({
            Filter: '',
            disableFilters: true,
        }),
        []
    );

    const deleteAlarm = () => {
        NMService.deleteAlarmRule(selectedAlarmId).then(() => 
            addGlobalMessage({
                header: 'Alarm deleted successfully!',
                content: 'Alarm deleted successfully.',
                type: 'positive',
            })
        )
        .catch(e => null)
        .finally(() => {
            props.refetch()
            setOpenDeleteModal(false)
            setSelectedAlarmId(undefined)
        })
    }

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: 'actions',
                Cell: ({ row, disabled }) => {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            <PermissionsGateV hasPermission={hasPermision}>
                                <Button
                                    circular
                                    disabled={disabled}
                                    icon="edit"
                                    onClick={() => {
                                        props.toggleForm(row.original.id, 'edit');
                                        setSelectedAlarmId(row.original.id);
                                    }}
                                />
                            </PermissionsGateV>
                            <PermissionsGateV hasPermission={hasPermision}>
                                <Button
                                    circular
                                    icon="trash"
                                    onClick={() => {
                                        setOpenDeleteModal(true)
                                        setSelectedAlarmId(row.original.id)
                                    }}
                                />
                            </PermissionsGateV>
                        </div>
                    );
                },
                disableGlobalFilter: true,
                disableFilters: true,
                width: 2,
            },
        ]);
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        visibleColumns,
        allColumns,
        state: { pageIndex, pageSize, globalFilter },
        setGlobalFilter,
        setAllFilters,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            initialState: { pageSize: 10, pageIndex: 0 },
        },
        useFilters,
        useGlobalFilter,
        usePagination,
        tableHooks,
    );

    const advancedFilterHandler = () => {
        setAdvFilter(!advFilter);
        setAllFilters([]);
        setGlobalFilter([]);
    };

    const getRowStatus = (row) => {
        const { hwid, module, scope, instanceid, location, tag } = row.original;
        let isDisabledRow = false;
        if (!nodeList) return false;
        if (scope === 'none' && scope === 'module') {
            return false;
        } else if (scope === 'hwid') {
            return (nodeList || [])?.findIndex((e) => e.modulelist.includes(module) && e.hwid === hwid) === -1
        } else if (scope === 'instance') {
            const nodeid = (nodeList || [])?.find(e => e.hwid === hwid)?.id
            const relevantModules = allModules
                .filter(e => e.moduleid === module &&
                    e.instanceid === instanceid &&
                    e.nodeid === nodeid)
            return relevantModules.length === 0
        } else if (scope === "location") {
            return (allLocations || [])?.findIndex(e => e.id === location.id) === -1
        } else if (scope === "tag") {
            return (nodeList || [])?.findIndex(e => e.modulelist.includes(module) && e.tags.includes(tag)) === -1
        }
        return isDisabledRow;
    };


    return (
        <>
            <div className="field" style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <Checkbox
                    toggle
                    label="Advanced filtering"
                    checked={advFilter}
                    onChange={advancedFilterHandler}
                />
            </div>

            {!advFilter && <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />}

            {advFilter &&
                allColumns.map(
                    (column) =>
                        column.canFilter && (
                            <div style={{ marginTop: '1rem' }} key={uuidv4()}>
                                {column.render('Filter')}
                            </div>
                        )
                )}

            <Table celled size="small" {...getTableProps()}>
                <Table.Header>
                    {headerGroups.map((headerGroup) => (
                        <Table.Row {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Table.HeaderCell {...column.getHeaderProps([{ width: column.width }])}>
                                    {column.render('Header')}
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Header>
                <Table.Body {...getTableBodyProps()}>
                    {page.length > 0 ?
                        page.map((row, i) => {
                            prepareRow(row);
                            const rowStatus = getRowStatus(row);
                            return (
                                <Table.Row
                                    warning={rowStatus}
                                    active={selectedAlarmId && selectedAlarmId === row.original.id ? true : false}
                                    {...row.getRowProps()}
                                >
                                    {row.cells.map((cell) => 
                                        <Table.Cell {...cell.getCellProps()}>
                                            {cell.render('Cell', { disabled: rowStatus, severityList })}
                                        </Table.Cell>
                                    )}
                                </Table.Row>
                            );
                        })
                        :
                        <Table.Row>
                            <Table.Cell colSpan={visibleColumns.length} textAlign="center">
                                No data available
                            </Table.Cell>
                        </Table.Row>}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={visibleColumns.length}>
                            <Grid>
                                <Grid.Row verticalAlign="middle">
                                    <Grid.Column width={4}>
                                        <span>
                                            &emsp;Show&emsp;
                                            <Dropdown
                                                inline
                                                value={pageSize}
                                                onChange={(e, { value }) => {
                                                    setPageSize(Number(value));
                                                }}
                                                options={[10, 20, 30, 40, 50].map((pageSize) => ({
                                                    key: pageSize,
                                                    value: pageSize,
                                                    text: pageSize,
                                                }))}
                                            />
                                            &emsp;entries
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column width={8} textAlign='center'>
                                        <Menu size="mini" pagination>
                                            <Menu.Item
                                                as="a"
                                                icon="angle double left"
                                                onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle left"
                                                onClick={() => previousPage()}
                                                disabled={!canPreviousPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                content={`${pageCount !== 0 ? (pageIndex + 1) : 0}/${pageCount}`}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle right"
                                                onClick={() => nextPage()}
                                                disabled={!canNextPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle double right"
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            />
                                        </Menu>
                                    </Grid.Column>
                                    <Grid.Column width={4} textAlign='right'>

                                    <PermissionsGateV hasPermission={hasPermision}>
                                        <Button
                                            primary
                                            size='tiny'
                                            floated='right'
                                            content='Add new alarm'
                                            onClick={() => props.toggleForm(undefined, 'add')}
                                        />
                                    </PermissionsGateV>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>

            <ConfirmationModal 
                open={openDeleteModal}
                header="Delete alarm"
                content="Are you sure you want to delete alarm?"
                onConfirm={deleteAlarm}
                onDismiss={() => {
                    setOpenDeleteModal(false)
                    setSelectedAlarmId(undefined)
                }}
            />
        </>
    );
}

export default AlarmsTable;
