import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useFilters, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { Checkbox, Menu, Button, Table, Grid, Dropdown, Popup, Modal, Icon } from 'semantic-ui-react';
import MultiSelectColumnFilter from './MultiSelectColumnFilter';
import DateFormat from '../../components/dateFormat/DateFormat';
import GlobalFilter from '../../components/globalFilter/GlobalFilter';
import { uuidv4 } from '../../utils/uuidv4';
import AssignTemplateForm from '../../components/assignTemplateForm/AssignTemplateForm';
import NMService from '../../services/nm.service';
import AssignLicenseForm from '../../components/assignLicenseForm/AssignLicenseForm';
import { addGlobalMessageAtom } from '../../store/globalMessage';
import { useAtom, useSetAtom } from 'jotai';
import { managedNodesAtom } from '../../store/managedNodes';
import { useGetGlobalPermission } from '../../hooks/useGetGlobalPermission';
import { NODE_ADMIN } from '../../constants/layout';
import { PermissionsGateV } from '../../layouts/PermissionGate/PermissionGateV';
import { nodesTableAtom, nodesTableActions } from '../../store/nodesTable';

function ManagedProbesTable(props) {
    const hiddenColumnsNew = ['name', 'description', 'tags', 'locationdata', 'modulelist', 'modules', 'license']
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const hasPermission = useGetGlobalPermission(NODE_ADMIN);
    const updateNodesState = useSetAtom(managedNodesAtom);
    const [globalTableProps, dispatchGlobalTableProps] = useAtom(nodesTableAtom);
    const [advFilter, setAdvFilter] = useState(false);
    const [showForm, setShowForm] = useState(undefined);
    const data = useMemo(() => props.data, [props.data]);

    const column = [
        {
            id: 'id',
            Header: 'ID',
            accessor: 'id',
        },
        {
            id: 'name',
            Header: 'Name',
            accessor: 'name',
        },
        {
            id: 'description',
            Header: 'Description',
            accessor: 'description',
        },
        {
            id: 'hwid',
            Header: 'HWID',
            accessor: 'hwid',
            isVisible: false,
        },
        {
            id: 'locationdata',
            Header: 'Location',
            accessor: d => d.locationdata?.name || "",
            Filter: MultiSelectColumnFilter,
            filter: 'multipleLocation',
            disableFilters: false,
        },
        {
            id: 'ip',
            Header: 'Mgmt IP',
            accessor: 'ip',
        },
        {
            id: 'tags',
            Header: 'Tags',
            accessor: (d) => {
                if (d.tags) {
                    return d.tags.join(', ');
                } else {
                    return "";
                }
            },
            Filter: MultiSelectColumnFilter,
            filter: 'multiple',
            disableFilters: false,
        },
        {
            id: 'devicemodel',
            Header: 'Device model',
            accessor: 'devicemodel',
        },
        {
            id: 'seenbefore',
            Header: 'Seen before',
            accessor: (d) => {
                if (d.seenbefore === -1) {
                    return <span style={{ color: "red" }}>unknown</span>
                }
                let seconds = d.seenbefore % 60;
                let minutes = Math.floor(d.seenbefore / 60);
                let hours = Math.floor(minutes / 60);
                let days = Math.floor(hours / 24);
                minutes -= hours * 60;
                hours -= days * 24;
                const color = d.seenbefore < 3600 ? '#000000' : d.seenbefore < 86400 ? '#dc7b41' : '#ff0000';
                return (
                    <span style={{ color: color }}>
                        {(isNaN(days) ? '00' : (days < 10 ? '0' : '') + days) +
                            `day${days % 10 === 1 && days !== 11 ? "" : "s"} ` +
                            (isNaN(hours) ? '00' : (hours < 10 ? '0' : '') + hours) +
                            ':' +
                            (isNaN(minutes) ? '00' : (minutes < 10 ? '0' : '') + minutes) +
                            ':' +
                            (seconds < 10 ? '0' : '') +
                            seconds}
                    </span>
                );
            },
            sortType: useMemo(
                () => (rowA, rowB, id, desc) => {
                    if (rowA.original[id] === rowB.original[id]) {
                        return 0;
                    }
                    return rowA.original[id] > rowB.original[id] ? 1 : -1;
                },
                []
            ),
        },
        {
            id: 'registryTimestamp',
            Header: 'Last seen',
            accessor: (d) => d.registryTimestamp ?
                            <DateFormat timestamp={d.registryTimestamp} threshold={false} /> :
                            <span style={{ color: "red" }}>unknown</span>,
            sortType: useMemo(
                () => (rowA, rowB, id, desc) => {
                    return rowA.original[id]?.localeCompare(rowB.original[id]) || 0;
                },
                []
            ),
            width: '2',
        },
        {
            id: 'modules',
            Header: 'Modules',
            accessor: (d) => (
                <Popup
                    on={'hover'}
                    wide
                    position="bottom left"
                    trigger={<div style={{ cursor: 'pointer', paddingLeft: '1rem' }}>{d.modulelist.length}</div>}
                >
                    {d.modulelist.length === 0 ? (
                        <p style={{ marginBottom: "0" }}>
                            No used or unassigned module instances.
                        </p>
                    ) : (
                        <>
                            <p style={{ marginBottom: "0" }}>
                                Module list ({d.modules} instance{d.modules % 10 === 1 && d.modules !== 11 ? "" : "s"}):
                            </p>
                            <ul style={{ marginTop: "0", paddingLeft: "20px" }}>
                                {d.modulelist.map((module, index) => (
                                    <li key={`${module}_${index}`}>{module}</li>
                                ))}
                            </ul>
                        </>
                    )}
                </Popup>
            ),
            sortType: useMemo(
                () => (rowA, rowB, id) => {
                    if (rowA.original[id] === rowB.original[id]) {
                        return 0;
                    }
                    return rowA.original[id] > rowB.original[id] ? 1 : -1;
                },
                []
            ),
            width: '1',
        },
        {
            id: 'modulelist',
            Header: 'Module List',
            accessor: (d) => d.modulelist.join(', '),
            Filter: MultiSelectColumnFilter,
            filter: 'multiple',
            disableFilters: false,
        },
    ];
    const columns = useMemo(() => column, []); // eslint-disable-line

    const filterTypes = useMemo(() => ({
            multiple: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.original[id];
                    if (rowValue === undefined) { return false }
                    return Array.isArray(rowValue) ? filterValue.some((e) => rowValue.includes(e)) : filterValue.includes(rowValue)
                });
            },
            multipleLocation: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    if (rowValue === undefined) { return false }
                    return filterValue.includes(rowValue);
                });
            }}), []);

    const defaultColumn = useMemo(() => ({ Filter: '', disableFilters: true }), []);

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: '',
                id: 'actions',
                width: '2',
                Cell: ({ row }) => {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                circular
                                icon="settings"
                                color={row.original.license ? null : "red"}
                                as={Link}
                                to={`/node/${props.type}/${row.original.id}`}
                            />
                            <PermissionsGateV hasPermission={hasPermission}>
                                <Button
                                    circular
                                    icon="redo"
                                    onClick={() =>
                                        updateNodesState(
                                            { type: 'open-modal', value: { showModal: 'reload', nodeData: row.original } }
                                        )
                                    }
                                />
                            </PermissionsGateV>
                            <PermissionsGateV hasPermission={hasPermission}>
                                <Button
                                    circular
                                    icon="trash"
                                    onClick={() =>
                                        updateNodesState(
                                            { type: 'open-modal', value: { showModal: 'delete', nodeData: row.original } }
                                        )
                                    }
                                />
                            </PermissionsGateV>
                        </div>
                    );
                },
                disableGlobalFilter: true,
                disableFilters: true,
            },
        ]);
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        rows,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        visibleColumns,
        setHiddenColumns,
        allColumns,
        state: { pageIndex, pageSize, globalFilter, hiddenColumns },
        setGlobalFilter,
        setAllFilters,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            initialState: {
                pageIndex: 0,
                globalFilter: globalTableProps.globalFilter,
                hiddenColumns: props.new ? hiddenColumnsNew :globalTableProps.hiddenColumnsManaged
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        tableHooks
    );

    const advancedFilterHandler = () => {
        setAdvFilter(!advFilter);
        setAllFilters([]);
        setGlobalFilter([]);
        dispatchGlobalTableProps({ type: nodesTableActions.REMOVE_FILTER })
    };

    const dismissForm = () => setShowForm(undefined)

    const submitAssignTemplateForm = (values) => {
        const nodeList = page.map((e) => e.values.id);
        const data = { nodeList, ...values };

        NMService.assignTemplateToNodeList(data).then((response) => {
            dismissForm();
            props.refreshData();
            addGlobalMessage({
                header: 'Template applied successfully',
                content: `Template was successfully applied to node${nodeList.length === 1 ? '' : 's'}.`,
                type: 'positive',
            })
            setGlobalFilter(globalTableProps.globalFilter);
        }).catch(e => null)
    };

    const submitAssignLicenseForm = (type) => {
        const nodeList = page.map((e) => e.original.id)
        const data = { nodeList, type }

        NMService.assignLicenseToNodeList(data).then((r) =>
                addGlobalMessage({
                    header: "License applied successfully",
                    content: "License application successful",
                    type: 'positive',
                })
        ).catch(e => null)
        .finally(() => {
            dismissForm();
            props.refreshData();
            setGlobalFilter(globalTableProps.globalFilter);
        })
    };

    const fetchDefaultColumnValues = useCallback(() =>
        NMService.getGlobalConfigValue("nodemanager.nodetable.defaultcolumns").then((response) => {
            if (response.data.rv !== "ERROR") {
                let newColumns = response.data
                    .filter((e) => e.isEditable)
                    .filter((e) => !e.isChecked)
                    .map((e) => e.id);
                newColumns.push("modulelist");
                if (globalTableProps.dbColumnsValue !== JSON.stringify(newColumns)) {
                    dispatchGlobalTableProps({ type: nodesTableActions.UPDATE_COLUMNS, value: JSON.stringify(newColumns) })
                    dispatchGlobalTableProps({ type: nodesTableActions.UPDATE_HIDDEN, value: newColumns })
                    setHiddenColumns(newColumns);
                }
            }
        }).catch(e => null), [setHiddenColumns]); // eslint-disable-line

    useEffect(() => {
        props.new ? setHiddenColumns(hiddenColumnsNew) : fetchDefaultColumnValues()
    }, [fetchDefaultColumnValues]) // eslint-disable-line

    useEffect(() => {
        if (!props.new) dispatchGlobalTableProps({ type: nodesTableActions.UPDATE_HIDDEN, value: hiddenColumns })
    }, [visibleColumns.length, props.new, hiddenColumns]) // eslint-disable-line

    useEffect(() => {
        setGlobalFilter(globalTableProps.globalFilter)
    }, [rows.length]) // eslint-disable-line

    return (
        <>
            {!props.new && (
                <>
                    <div className="field" style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                        <Checkbox
                            toggle
                            label="Advanced filtering"
                            checked={advFilter}
                            onChange={advancedFilterHandler}
                        />
                    </div>

                    {advFilter && allColumns.map((column) =>
                        column.canFilter && (
                            <React.Fragment key={uuidv4()}>{column.render('Filter')}</React.Fragment>
                        ))}
                </>)}

            {!advFilter && (
                <GlobalFilter
                    globalFilter={globalTableProps.globalFilter || globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    resetPage={() => gotoPage(0)}
                    setMemo={(v) => dispatchGlobalTableProps({ type: nodesTableActions.UPDATE_FILTER, value: v})}
                />)}

            <Menu compact style={advFilter ? {} : { marginLeft: '1rem' }} vertical size="small">
                <Dropdown item text="Actions" disabled={rows.length === 0}>
                    <Dropdown.Menu>
                        {!props.new && (
                            <Dropdown.Item onClick={() => setShowForm('assignTemplateForm')}>
                                Assign template
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={() => setShowForm('massDelete')}>Delete nodes</Dropdown.Item>
                        <Dropdown.Item onClick={() => setShowForm('assignLicenseForm')}>Assign license</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu>

            {!props.new && (
                <Menu vertical compact style={{ marginLeft: '1rem' }} size="small">
                    <Dropdown item text="Show columns">
                        <Dropdown.Menu>
                            {allColumns.map((column) => {
                                if (column.Header !== 'ID' && column.Header !== '' && column.Header !== 'Module List') {
                                    return (
                                        <Dropdown.Item key={column.id} style={{ position: "relative", height: "35px", width: "128px" }}>
                                            <label style={{ cursor: "pointer", height: "100%", width: "100%", position: "absolute", right: 0, top: 0, padding: "0.8em 1em 1em 1.125em" }} >
                                                <input
                                                    type="checkbox"
                                                    {...column.getToggleHiddenProps()}
                                                    style={{ pointerEvents: "none" }}
                                                    name={column.Header}
                                                />
                                                &ensp;{column.Header}
                                            </label>
                                        </Dropdown.Item>
                                    );
                                }
                                return null;
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu>
            )}

            {showForm === 'assignTemplateForm' && rows.length !== 0 && (
                <AssignTemplateForm submitForm={submitAssignTemplateForm} dismissForm={dismissForm} />
            )}
            {showForm === 'assignLicenseForm' && rows.length !== 0 && (
                <AssignLicenseForm
                    submitForm={submitAssignLicenseForm}
                    dismissForm={dismissForm}
                />
            )}

            <Table celled size="small" {...getTableProps()}>
                <Table.Header>
                    {headerGroups.map((headerGroup) => (
                        <Table.Row {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Table.HeaderCell
                                    {...column.getHeaderProps([
                                        column.width > 16 ? {} : { width: column.width },
                                        column.getSortByToggleProps(),
                                    ])}
                                >
                                    {column.render('Header')}
                                    <span>{column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}</span>
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Header>
                <Table.Body {...getTableBodyProps()}>
                    {page.length !== 0 ? 
                        page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <Table.Row
                                    active={props.selectedProbeId && props.selectedProbeId === row.id ? true : false}
                                    {...row.getRowProps()}
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <Table.Cell {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </Table.Cell>
                                        )
                                    })}
                                </Table.Row>
                            )})
                        :
                        <Table.Row>
                            <Table.Cell colSpan={visibleColumns.length} textAlign="center">
                                No data available
                            </Table.Cell>
                        </Table.Row>}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={visibleColumns.length}>
                            <Grid>
                                <Grid.Row verticalAlign="middle" columns={2}>
                                    <Grid.Column width={4}>
                                        <span>
                                            &emsp;Show&emsp;
                                            <Dropdown
                                                inline
                                                value={pageSize}
                                                onChange={(e, { value }) => {
                                                    setPageSize(Number(value));
                                                }}
                                                options={[10, 20, 30, 40, 50].map((pageSize) => ({
                                                    key: pageSize,
                                                    value: pageSize,
                                                    text: pageSize,
                                                }))}
                                            />
                                            &emsp;page entries ( {rows.length} {globalTableProps.globalFilter ? 'filtered' : 'total'} node
                                            {rows.length === 1 ? '' : 's'} )
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column width={8} textAlign='center'>
                                        <Menu size="mini" pagination>
                                            <Menu.Item
                                                as="a"
                                                icon="angle double left"
                                                onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle left"
                                                onClick={() => previousPage()}
                                                disabled={!canPreviousPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                content={`${pageCount !== 0 ? (pageIndex + 1) : 0}/${pageCount}`}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle right"
                                                onClick={() => nextPage()}
                                                disabled={!canNextPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle double right"
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            />
                                        </Menu>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>

            <Modal basic size="small" open={showForm === 'massDelete' && rows.length !== 0}>
                <Modal.Header>
                    <Icon name="archive" style={{ marginRight: '1.5rem' }} />
                    Delete node
                </Modal.Header>
                <Modal.Content>
                    <p>Are you sure you want to delete selected nodes?</p>
                    {page.map(({ original }) => (
                        <p key={original.hwid}>&emsp;{`${original.hwid}${!props.new ? ', ' + original.name : ''}`}</p>
                    ))}
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color="red" inverted onClick={() => setShowForm(undefined)}>
                        <Icon name="remove" /> No
                    </Button>
                    <Button
                        color="green"
                        inverted
                        onClick={async () => {
                            for (const row of page) {
                                await NMService.deleteNode(row.original.id).then((response) => {
                                    addGlobalMessage({
                                        header: 'Nodes deleted successfully!',
                                        content: `Node with HWID "${row.original.hwid}" is unmanaged now.`,
                                        type: 'positive',
                                    })
                                })
                                .catch(e => null)
                                .finally(r => setShowForm(''))
                            }
                            props.refreshData();
                        }}
                    >
                        <Icon name="checkmark" /> Delete
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default (ManagedProbesTable);