import { Link, Navigate, useOutlet } from "react-router-dom";
import { Container, Menu } from "semantic-ui-react";
import { useAuth, useUserInfo } from "../hooks/useAuth";
import GlobalMessages from "./GlobalMessages/GlobalMessages";
import { useQueryClient } from "@tanstack/react-query";

export const MultiTenantLayout = () => {
    useQueryClient().clear();
    const outlet = useOutlet();
    const { userdata } = useUserInfo();
    const { logout } = useAuth();

    if (userdata.role !== "tenantadmin") {
        return <Navigate to="/" />;
    }

    return (
        <>
            <Menu inverted color='blue'>
                <Menu.Item as={Link} to="/grafana/" target="_blank" icon="chart line" content="Monitoring" />
                <Menu.Item position="right" content="Logout" onClick={() => logout()}/>
            </Menu>
            <Container style={{ width: '1350px' }}>
                <GlobalMessages />
                {outlet}
            </Container>
        </>
    );
};