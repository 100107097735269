import { useMemo } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import NMService from '../../../services/nm.service';
import ModuleConfig from '../ModuleConfig/ModuleConfig';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import AssignTo from './AssignTo';
import ModuleBoxLayout from '../../../layouts/ModuleBox/ModuleBox';
import AnchorStyleButton from '../../../layouts/AnchorStyleButton/AnchorStyleButton';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import { addGlobalMessageAtom } from '../../../store/globalMessage';
import { useFetchProbeModuleBundle } from '../../../hooks/useFetchProbeModuleBundle';
import {
    getModelDataByUuid,
    getUnassignedModulePersmissionByUuid,
    unassignedModulesAtom
} from '../../../store/unassignedModules';


const UnassignedModule = (props) => {
    const probeId = useMemo(() => props.probeId, [props.probeId]);
    const module = useMemo(() => props.moduleData, [props.moduleData]);

    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [unassignedModulesState, setUnassignedModulesState] = useAtom(unassignedModulesAtom);
    const moduleHasPermission = useAtomValue(useMemo(() => getUnassignedModulePersmissionByUuid(module.uuid), [module.uuid]))
    const modelData = useAtomValue(useMemo(() => getModelDataByUuid(module.uuid), [module.uuid]))

    const {
        isLoading: moduleBundleIsLoading,
        refetch: refetchModuleBundle
    } = useFetchProbeModuleBundle({ probeid: probeId });

    const deleteUnassignedModule = () => !module.uuid ? null :
        NMService.deleteUnassignedModule(module.uuid).then(() =>
                addGlobalMessage({
                    header: 'Delete unassigned module',
                    content: `Unassigned module was successfully deleted.`,
                    type: 'positive',
                }))
            .catch(() => setUnassignedModulesState({ type: 'select-module', value: '' }))
            .finally(() => refetchModuleBundle())

    const assignModule = ({ uuid, parent }) => !uuid ? null :
        NMService.assignUnassignedModule({ uuid, parent }).then(() => 
                addGlobalMessage({
                        header: 'Module was assigned!',
                        content: 'Module was successfully assigned.',
                        type: 'positive',
                }))
            .catch(() => setUnassignedModulesState({ type: 'select-module', value: '' }))
            .finally(() => refetchModuleBundle())

    const onConfigFormSubmit = (data) =>
        NMService.updateNodeModuleConfig(data).then(() =>
                addGlobalMessage({
                    header: 'Module configuration was updated!',
                    content: 'Module configuration was successfully updated.',
                    type: 'positive',
                }))
            .catch(e => null)
            .finally(() => refetchModuleBundle())

    const selectModuleType = (uuid, moduleType) =>
        setUnassignedModulesState({
            type: 'select-module',
            value: {
                selectedModuleUuid: uuid,
                showModuleType: moduleType
            }
        })

    const isSelected = Boolean(unassignedModulesState.selectedModuleUuid === module.uuid);

    return (moduleBundleIsLoading || !modelData) ? null : (
        <>
            <ModuleBoxLayout>
                <Grid padded>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            {module.moduleid} (v{module.version})
                        </Grid.Column>
                        <Grid.Column width={2}>{module.instanceid}</Grid.Column>
                        <Grid.Column width={3}></Grid.Column>
                        <Grid.Column width={7}>
                            <PermissionsGateV hasPermission={moduleHasPermission}>
                                <AnchorStyleButton
                                    selected={isSelected && unassignedModulesState.showModuleType === 'assignToUse'}
                                    onClick={() => {
                                        if (modelData.isTopLevelFramework) {
                                            assignModule({ uuid: module.uuid, parent: '' })
                                        } else {
                                            selectModuleType(module.uuid, 'assignToUse')
                                        }
                                    }}
                                >
                                    <Icon name="plus" link/>
                                    {(!modelData.isTopLevelFramework) ? 'assign to framework' : 'assign to use'}
                                </AnchorStyleButton>
                            </PermissionsGateV>
                             {module.moduleid !== "ethernetframework" &&
                                <>
                                    &emsp;
                                    <AnchorStyleButton
                                        selected={isSelected && unassignedModulesState.showModuleType === 'moduleConfig'}
                                        onClick={() => {
                                            if (isSelected && unassignedModulesState.showModuleType === 'moduleConfig') {
                                                selectModuleType('', '')
                                            } else {
                                                selectModuleType(module.uuid, 'moduleConfig')
                                            }
                                        }}
                                    >
                                        <Icon name="cog" /> module config
                                    </AnchorStyleButton>
                                </>
                            }
                            &emsp;
                            <PermissionsGateV hasPermission={moduleHasPermission}>
                                <AnchorStyleButton
                                    selected={isSelected && unassignedModulesState.showModuleType === 'deleteModal'}
                                    onClick={() => selectModuleType(module.uuid, 'deleteModal')}
                                >
                                    <Icon name="trash" /> delete
                                </AnchorStyleButton>
                            </PermissionsGateV>
                            &emsp; &emsp;&emsp;
                        </Grid.Column>
                        <Grid.Column width={1}>
                            {isSelected && unassignedModulesState.showModuleType !== 'deleteModal' && (
                                <AnchorStyleButton
                                    onClick={() => selectModuleType('', '')}
                                >
                                    <Icon name="close" />
                                </AnchorStyleButton>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    {isSelected && unassignedModulesState.showModuleType !== 'deleteModal' && (
                        <Grid.Row>
                            <Grid.Column>
                                {(unassignedModulesState.showModuleType === 'assignToUse') && (
                                    <AssignTo
                                        probeData={props.probeData}
                                        data={module}
                                        hasPermission={moduleHasPermission}
                                        onFormSubmit={assignModule}
                                    />
                                )}
                                {(unassignedModulesState.showModuleType === 'moduleConfig') && (
                                    <ModuleConfig
                                        probeData={props.probeData}
                                        data={module}
                                        hasPermission={moduleHasPermission}
                                        onFormSubmit={onConfigFormSubmit}
                                    />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
            </ModuleBoxLayout>
            <ConfirmationModal
                open={unassignedModulesState.showModuleType === 'deleteModal' && isSelected}
                header="Delete unassigned module"
                content="Are you sure you want to delete unassigned module?"
                onConfirm={deleteUnassignedModule}
                onDismiss={() => selectModuleType('', '')}
            />
        </>
    );
};

export default UnassignedModule;