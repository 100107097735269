import { useState } from "react";
import { useAtomValue } from "jotai";
import { useFetchAllLicenses } from "../../hooks/useFetchAllLicenses";
import { licenseMgmtActions, licenseMgmtAtom } from "../../store/licenseManagment";
import AddLicense from "./AddLicense";
import LicenseInfoSegment from "./LicenseInfoSegment";
import LicenseTable from "./LicenseTable";
import CustomHeader from "../../components/CustomHeader";

const LicenseSegment = () => {
    const [show, setShow] = useState(false);
    const { showType } = useAtomValue(licenseMgmtAtom);

    const {
        data,
        isLoading,
        isFetching
    } = useFetchAllLicenses({
        options: {
            refetchOnMount: 'always'
        }
    });

    if (isLoading || isFetching) {
        return null;
    }

    return (
        <>
            <CustomHeader
                title='Licenses'
                toggle={() => setShow((p) => !p)}
                show={show}
            />
            {show && <LicenseTable data={data} />}
            {(show && showType === licenseMgmtActions.SHOW_LICENSE_FORM) && <AddLicense />}
            {(show && showType === licenseMgmtActions.SHOW_LICENSE_INFO) && <LicenseInfoSegment />}
        </>
    );
};

export default LicenseSegment;
