import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { uuidv4 } from "../../../utils/uuidv4";

const FileUpload = (props) => {
    const [newFile, setNewFile] = useState(undefined);

    const fileLoader = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        reader.onload = async (e) => {
            fileParser(e.target.result);
        };
        reader.readAsText(newFile);
    };

    const fileParser = (content) => {
        const rows = content
            .split("\n")
            .map((e) => e.trim())
            .filter((e) => e !== "");
        const columns = rows[0].split(",");
        const parsedList = [];
        for (let i = 1; i < rows.length; i++) {
            let data = rows[i].split(",").map((e) => e.trim());
            if (data.join("") === "") {
                continue;
            }
            let obj = {};
            for (let j = 0; j < data.length; j++) {
                obj[columns[j]] = data[j];
            }
            obj.uuid = uuidv4();
            parsedList.push(obj);
        }
        setNewFile(undefined);
        props.setFileContent(parsedList);
    };

    return (
        <Form onSubmit={fileLoader}>
            <Form.Group>
                <Form.Field width={4}>
                    <Button
                        as="label"
                        htmlFor="file"
                        type="button"
                        content={
                            newFile === undefined
                                ? "Choose .csv file..."
                                : newFile.name.length > 35
                                ? newFile.name.substring(0, 26) + "(...).csv"
                                : newFile.name
                        }
                        onClick={() => props.setFileContent([])}
                    />
                    <input
                        type={"file"}
                        accept={".csv"}
                        id="file"
                        hidden
                        onChange={(e) => setNewFile(e.target.files[0])}
                    />
                </Form.Field>
                <Form.Button
                    type="submit"
                    primary={newFile !== undefined}
                    disabled={newFile === undefined}
                    content="Load table preview"
                />
            </Form.Group>
        </Form>
    );
};

export default FileUpload;
