import { atomWithReducer } from 'jotai/utils';

export const InstaceActions = {
    AddNewTenant: 'add-new-tenant',
    EditTenant: 'edit-tenant',
    SetTenant: 'set-tenant',
    ActivateTenant: 'activate-tenant',
    DeActivateTenant: 'deactivate-tenant',
    DeleteTenant: 'delete-tenant',
    DeselectTenant: 'deselect-tenant'
}

const INITIAL_STATE = {
    selectedTenantUuid: '',
    showType: '',
}

const usedInstancesReducer = (prevState, { type, value }) => {
    switch (type) {
        case InstaceActions.AddNewTenant:
            return {
                ...prevState,
                selectedTenantUuid: '',
                showType: InstaceActions.AddNewTenant,
            };
        case InstaceActions.EditTenant:
            return {
                ...prevState,
                selectedTenantUuid: value,
                showType: InstaceActions.EditTenant,
            };
        case InstaceActions.DeleteTenant:
            return {
                ...prevState,
                selectedTenantUuid: value,
                showType: InstaceActions.DeleteTenant,
            };
        case InstaceActions.ActivateTenant:
            return {
                ...prevState,
                selectedTenantUuid: value,
                showType: InstaceActions.ActivateTenant,
            };
        case InstaceActions.SetTenant:
            return {
                ...prevState,
                selectedTenantUuid: value,
                showType: InstaceActions.SetTenant,
            };
        case InstaceActions.DeActivateTenant:
            return {
                ...prevState,
                selectedTenantUuid: value,
                showType: InstaceActions.DeActivateTenant,
            };
        case InstaceActions.DeselectTenant:
            return {
                ...prevState,
                selectedTenantUuid: '',
                showType: '',
            };
        default:
            return INITIAL_STATE

    }
}

export const multiTenantAtom = atomWithReducer(INITIAL_STATE, usedInstancesReducer);
