import { useState } from "react";
import { Input } from "semantic-ui-react";
import { useAsyncDebounce } from "react-table";

const GlobalFilter = ({ globalFilter, setGlobalFilter, resetPage, setMemo = undefined }) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
      if (resetPage !== undefined) { resetPage() }
      if (setMemo) { setMemo(value) }
      setGlobalFilter(value || undefined);
  }, 300);

  return (
      <Input
          icon="search"
          placeholder="Search..."
          size="small"
          value={value || ''}
          onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
          }}
      />
  );
}

export default GlobalFilter;