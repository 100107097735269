import React, { useMemo } from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { strftime } from '../../../utils/strftime';
import CustomTable from '../../../components/CustomTable';

function DhcpSubnetTable(props) {
    const data = useMemo(() => props.data, [props.data]);
    const columns = useMemo(
        () => [
            {
                id: 'subnet',
                Header: 'Subnet',
                accessor: 'subnet',
                width: 3,
            },
            {
                id: 'enabled',
                Header: 'Enabled',
                accessor: (d) => <Icon color={d.enabled ? "green" : "black"} name={d.enabled ? "checkmark" : "close"} />,
                width: 1,
                Cell: (d) => <div style={{ textAlign: 'center' }}>{d.value}</div>,
            },
            {
                id: 'relay',
                Header: 'Relay',
                accessor: (d) => {
                    try {
                        return d.relay['ip-addresses'].map((relay, index) => (
                            <React.Fragment key={`${index}_${relay.var}`}>
                                {`${relay.var}`}
                                <br />
                            </React.Fragment>
                        ));
                    } catch {
                        return null;
                    }
                },
                width: 3,
            },
            {
                id: 'pools',
                Header: 'Pools',
                accessor: (d) => {
                    return d.pools.map((pool, index) => (
                        <React.Fragment key={`${index}_${pool.startIpAddress}_${pool.endIpAddress}`}>
                            {`${pool.startIpAddress} - ${pool.endIpAddress}`}
                            <br />
                        </React.Fragment>
                    ));
                },
                width: 3,
            },
            {
                id: 'created',
                Header: 'Created',
                accessor: (d) => strftime('%Y-%m-%d %H:%M:%S', new Date(Date.parse(d.created))),
                width: 2,
            },
            {
                id: 'updated',
                Header: 'Updated',
                accessor: (d) => d.updated ? strftime('%Y-%m-%d %H:%M:%S', new Date(Date.parse(d.updated))) : '',
                width: 2,
            },
        ],
        []
    );

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: 'actions',
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            circular
                            icon="settings"
                            onClick={() => props.toggle(row.original, true, false)}
                        />
                        <Button
                            circular
                            icon="trash"
                            onClick={() => props.toggle(row.original, false, true)}
                        />
                    </div>,
                disableGlobalFilter: true,
                disableFilters: true,
                width: 2
            },
        ]);
    };

    return (
        <CustomTable
            data={data}
            columns={columns}
            tableHooks={tableHooks}
            footerButton={props.addNew}
        />
    );
}

export default DhcpSubnetTable;
