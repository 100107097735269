import { Checkbox, Dropdown, Grid, Icon, Menu, Table } from "semantic-ui-react";
import GlobalFilter from "./globalFilter/GlobalFilter";
import { useMemo, useState } from "react";
import { useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";

// data, columns, footerButton, tableHooks, additional, advancedSearch,
// active, fixed, notCelled, hiddenColumns, hideSearch
const CustomTable = (props) => {
    const data = useMemo(() => props.data, [props.data])
    const footerButton = !!props.footerButton ? props.footerButton() : null
    const additional = !!props.additional ? props.additional : null
    const [hideSearch, setHideSearch] = useState(!!props.hideSearch)
    const advancedSearch = !!props.advancedSearch ? props.advancedSearch() : null
    const warning = !!props.warning ? props.warning : (e) => false
    const active = props.active ?? null

    const defaultColumn = useMemo(() => ({ Filter: "", disableFilters: true }), []);

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        visibleColumns,
        state: { pageIndex, pageSize, globalFilter },
        setGlobalFilter,
        setAllFilters
    } = useTable(
        {
            columns: props.columns,
            data,
            autoResetSortBy: false,
            autoResetPage: false,
            defaultColumn,
            autoResetGlobalFilter: false,
            initialState: { pageSize: 10, pageIndex: 0, hiddenColumns: props.hiddenColumns ?? [] },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        props.tableHooks ? props.tableHooks : () => null,
    );

    const advancedFilterHandler = () => {
        setHideSearch(p => !p);
        setAllFilters([])
        setGlobalFilter([]);
    };

    return (
        <>
            {advancedSearch !== null ?
                <>
                    <div className="field" style={{ marginBottom: "1rem" }}>
                        <Checkbox
                            toggle
                            label="Advanced filtering"
                            checked={hideSearch}
                            onChange={advancedFilterHandler}
                        />
                    </div>
                    {hideSearch ? (
                        advancedSearch
                    ) : (
                        <GlobalFilter
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            resetPage={() => gotoPage(0)}
                        />
                    )}
                </>
                :
                <>
                    {!hideSearch &&
                        <GlobalFilter
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            resetPage={() => gotoPage(0)}
                        />}
                </>
            }
            {additional ? 
                <Checkbox
                    style={{ paddingLeft: '2rem' }}
                    toggle={additional.toggle}
                    label={additional.label}
                    checked={additional.checked}
                    onClick={() => {
                        gotoPage(0)
                        additional.onClick()
                    }}
                /> : null}
            <Table celled={!props.notCelled} fixed={!!props.fixed} size="small" {...getTableProps()}>
                <Table.Header>
                    {headerGroups.map((headerGroup) => (
                        <Table.Row {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((e) => (
                                <Table.HeaderCell
                                    {...e.getHeaderProps([e.getSortByToggleProps(), { style: e.style }])}
                                    width={e.width !== 150 ? e.width : null}
                                >
                                    {e.render('Header')}
                                    {e.isSorted && <Icon name={`caret ${e.isSortedDesc ? 'down' : 'up'}`}/>}
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Header>
                <Table.Body {...getTableBodyProps()}>
                    {page.length !== 0 ? 
                        page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <Table.Row
                                    warning={warning(row)}
                                    active={active && active === row.original.id}
                                    style={{ whiteSpace: "pre-wrap" }}
                                    {...row.getRowProps()}
                                >
                                    {row.cells.map((cell) => 
                                        <Table.Cell {...cell.getCellProps([{ style: cell.column.style }])}>
                                            {cell.render('Cell')}
                                        </Table.Cell>
                                    )}
                                </Table.Row>
                            );
                        })
                        :
                        <Table.Row>
                            <Table.Cell colSpan={visibleColumns.length} textAlign="center">
                                No data available
                            </Table.Cell>
                        </Table.Row>}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={visibleColumns.length} style={{ overflow: "visible" }}>
                            <Grid>
                                <Grid.Row verticalAlign="middle" columns={3}>
                                    <Grid.Column>
                                        <span style={{ float: 'left' }}>
                                            &ensp;Show&ensp;
                                            <Dropdown
                                                inline
                                                value={pageSize}
                                                onChange={(_, { value }) => setPageSize(+value)}
                                                options={[10, 20, 30, 40, 50].map((pageSize) => ({
                                                    key: pageSize,
                                                    value: pageSize,
                                                    text: pageSize,
                                                }))}
                                            />
                                            &ensp;entries
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column textAlign="center">
                                        <Menu size="mini" pagination>
                                            <Menu.Item
                                                as="a"
                                                icon="angle double left"
                                                onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle left"
                                                onClick={() => previousPage()}
                                                disabled={!canPreviousPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                content={`${pageCount !== 0 ? (pageIndex + 1) : 0}/${pageCount}`}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle right"
                                                onClick={() => nextPage()}
                                                disabled={!canNextPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle double right"
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            />
                                        </Menu>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        {footerButton}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </>
    )
}

export default CustomTable