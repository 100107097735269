import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Label, Menu } from 'semantic-ui-react';
import { ADMIN, READ_ONLY, USER } from '../../constants/layout';
import { useUserInfo } from '../../hooks/useAuth';
import { useFetchNodes } from '../../hooks/useFetchNodes';
import { useFetchRegistry } from '../../hooks/useFetchRegistry';
import ManagedProbes from '../managedNodes/ManagedProbes';
import UnManagedProbes from '../unmanagedNodes/UnManagedProbes';


const Nodes = () => {
    const { userdata, multitenant } = useUserInfo();
    const { state } = useLocation();
    const [activeIndex, setActiveIndex] = useState(state?.defaultTab || 0);

    const {
        data: unmanagedProbes,
        isLoading: unmanagedProbesIsLoading,
        refetch: refetchUnmanagedProbes
    } = useFetchRegistry({
        select: (data) => (data ?? []).filter((probe) => !probe.inuse),
        options: {
            refetchOnMount: 'always'
        }
    })

    const {
        data: registryData,
        isLoading: registryDataIsLoading,
        refetch: refetchRegistryData
    } = useFetchNodes({
        select: (data) => {
            const managed = (data ?? []).filter((probe) => probe.name !== undefined);
            const newlyManaged = (data ?? []).filter((probe) => probe.name === undefined)
            return { managed, newlyManaged }
        },
        options: {
            refetchOnMount: 'always'
        }
    })

    const restriction = (
        multitenant &&
        userdata?.tenantid &&
        (
            userdata?.role === READ_ONLY ||
            userdata?.role === USER ||
            (userdata?.role === ADMIN && !userdata?.probeadd)
        )
    )

    return (registryDataIsLoading || unmanagedProbesIsLoading) ? null : (
        <>
            <Menu pointing secondary widths={restriction ? 2 : 3}>
                <Menu.Item
                    link
                    active={activeIndex === 0}
                    onClick={() => {
                        refetchRegistryData();
                        setActiveIndex(0);
                    }}
                >
                    Managed nodes<Label>{(registryData.managed ?? []).length}</Label>
                </Menu.Item>
                <Menu.Item
                    link
                    active={activeIndex === 1}
                    onClick={() => {
                        refetchRegistryData();
                        setActiveIndex(1);
                    }}
                >
                    Newly managed nodes<Label>{(registryData.newlyManaged ?? []).length}</Label>
                </Menu.Item>
                {!restriction &&
                    <Menu.Item
                        link
                        active={activeIndex === 2}
                        onClick={() => {
                            refetchUnmanagedProbes();
                            setActiveIndex(2);
                        }}
                    >
                        Unmanaged nodes<Label>{(unmanagedProbes ?? []).length}</Label>
                    </Menu.Item>
                }
            </Menu>

            {(activeIndex === 0) && (
                <ManagedProbes
                    type="managed"
                    new={false}
                    data={registryData.managed ?? []}
                    refetch={() => {
                        refetchUnmanagedProbes()
                        refetchRegistryData()
                    }}
                />
            )}
            {(activeIndex === 1) && (
                <ManagedProbes
                    type="newlymanaged"
                    new={true}
                    data={registryData.newlyManaged ?? []}
                    refetch={() => {
                        refetchUnmanagedProbes()
                        refetchRegistryData()
                    }}
                />
            )}
            {(activeIndex === 2 && !restriction) &&
                <UnManagedProbes
                    type="unmanaged"
                    data={unmanagedProbes}
                    refetch={() => {
                        refetchUnmanagedProbes()
                        refetchRegistryData()
                    }}
                />
            }
        </>
    );
};

export default Nodes;
