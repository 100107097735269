import { atomWithReducer } from 'jotai/utils';

export const authBackendActions = {
    DeleteAuthBackend: 'delete-auth-backend',
    DeselectAuthBAckend: 'deselect-auth-backend'
}

const INITIAL_STATE = {
    selectedBackend: '',
    showType: '',
}

const authBackendReducer = (prevState, { type, value }) => {
    switch (type) {
        case authBackendActions.AddNewTenant:
            return {
                ...prevState,
                selectedBackend: value,
                showType: authBackendActions.DeleteAuthBackend,
            };
        case authBackendActions.DeselectAuthBAckend:
            return {
                ...prevState,
                selectedBackend: '',
                showType: '',
            };
        default:
            return INITIAL_STATE

    }
}

export const authBackendAtom = atomWithReducer(INITIAL_STATE, authBackendReducer);
